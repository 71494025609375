import { Grid, Paper } from "@mui/material";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { AiOutlineMail } from "react-icons/ai";
import { useNavigate } from "react-router-dom";

import CustomInput from "../../Components/Input/CustomInput";
import Main_Logo from "../../Assets/images/OHS-Buddy-Logo.svg";
import { BackButton, YellowColorButton,} from "../../Components/materialTableStyle/StyleTableCell";
import { postMethod } from "../../Components/Helper/api";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [Email, setEmail] = useState("");
  const validationemails = (email) => {
    /* eslint-disable */
    const regExp =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regExp.test(email);
  };
  const Submit = async() => {
    if (!validationemails(Email)) {
      toast.warn("Please Enter a valid email address");
      return;
    }
    try{
   
      let url = "superadmin/ForgotPassword";
      let method = "PUT"
      let params = {
        Email:Email.toLocaleLowerCase(),
      };
      let authtoken = "";
      let response = await postMethod({ url, params, authtoken, method });
      console.log("response",response)
      if(response.status){
        toast.info("You Should verify Your otp Now");
        navigate("/otpverify", { state: { Email: Email } });
      }else{
        if(response.message){
          toast.error(response.message)
        }else{
          toast.error("Something Went Wrong")
        }
      }
    }catch(Error){
      console.log("Error ForgotPassword ",Error)
      toast.error("Something went wrong")
    }
  
  };
  return (
    <Grid
      container
      lg={12}
      display={"flex"}
      flexDirection={"row"}
      justifyContent={"flex-start"}
      alignItems={"stretch"}
      className="AuthHeight"
    >
      <Grid
        container
        lg={12}
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"flex-start"}
        className=" background_image"
      >
        <Grid
          container
          xl={6}
          lg={6}
          md={6}
          sm={6}
          xs={2}
          className="p-5"
          display={"flex"}
          flexDirection={"column"}
          alignItems={"flex-start"}
          justifyContent={"flex-start"}
        >
          <img src={Main_Logo} alt="main_logo" />
          <h3 className="text-light mt-5" style={{ textAlign: "left" }}>
            <h3 style={{ color: "var(--Yellow_color)" }}>Workplace Safety </h3>
            Best Practice Platform
          </h3>
        </Grid>
        <Grid
          conatiner
          xl={6}
          lg={6}
          md={6}
          sm={6}
          xs={10}
          display={"flex"}
          flexDirection="flex"
          className="p-5"
          justifyContent={"center"}
          alignItems={"center"}
        >
          {" "}
          <Grid
            container
            xxl={6}
            xl={8}
            lg={10}
            display={"flex"}
            flexDierection="column"
          >
            <Paper
              style={{ backgroundColor: "#000" , opacity: 0.8 }}
              className="p-5 rounded w-100"
            >
              <Grid
                container
                lg={12}
                display={"flex"}
                flexDirection="column"
                alignItems={"flex-start"}
                justifyContent={"flex-start"}
              >
                <h4 className="text-light">Forgot Password</h4>
                <p className="text-light mt-4">Email</p>
                <div className="p-1" />
                <CustomInput
                  StartIcon={<AiOutlineMail  size={25}/>}
                  Value={Email}
                  placeHolder={"#fff"}
                  placeholder={"Enter Email"}
                  TextColor={"#ffff"}
                  BgColor={"#000"}
                  className={" border border-white border-rounded"}
                  handleInput={(e) => setEmail(e)}
                  iconColor={"#ffff"}
                  isBorder={true}
                />
                <Grid
                  container
                  display={"flex"}
                  flexDirection="row"
                  justifyContent="center"
                  alignItems="center"
                  className=" mt-5"
                  sx={{ overflow: "hidden" }}
                >
                  <BackButton
                    // type="submit"
                    className="bg-white"
                    onClick={() => navigate("/")}
                    style={{ width: "35%" }}
                  >
                    Back
                  </BackButton>
                  <div className="p-2" />
                  <YellowColorButton
                    type="submit"
                    className="resetpasswordBtn"
                    style={{ width: "35%" }}
                    onClick={() => Submit()}
                  >
                    Verify
                  </YellowColorButton>
                </Grid>
              </Grid>
              <div className="p-3" />
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ForgotPassword;
