import { CircularProgress, Grid, } from "@mui/material";
import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import { useNavigate } from "react-router-dom";

import { RiskStyledTableCell, StyledTableRow, CustomTablePagination } from "../../../Components/materialTableStyle/StyleTableCell";
import { formatedDateAndTime } from "../../../Components/DateFormats/DateFromat"
import AuthBtn from "../../../Components/Button/AuthBtn";
import ConfirmationModal from "../../../Components/Modal/ConfirmationModal"
import { postMethod } from "../../../Components/Helper/api"
const PeopleTable = ({ ClientsData, Typeof, GetClientData, GetExitRequestData, Job_name }) => {
    const authtoken = localStorage.getItem("@accessToken")
    const Admin = JSON.parse(localStorage.getItem("@users"))

    useEffect(() => {
        console.log("Job_name", Job_name)
    }, [Job_name])
    const navigate = useNavigate();
    const [InitialData, setInitialData] = useState([]);
    const [Loader, setLoader] = useState(false);

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [SelectedData, setSelectedData] = useState([]);
    const [Open, setOpen] = useState(false);
    const [JenkinsConsole, setJenkinsConsole] = useState("")

    useEffect(() => {
        setInitialData(ClientsData);
        setLoader(true);
    }, [ClientsData]);

    useEffect(() => {
        if (Loader) {
            setTimeout(() => {
                setLoader(false);
            }, 1000);
        }
    }, [Loader]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 5));
        setPage(0);
    };


    const GetClientDatas = (Datas) => {
        console.log("Datas", Datas)
        navigate(`/clientdetails/${Datas.tenants_id}`, { state: Datas });
    };

    const HandleSideModal = (number) => {
        GetConsoleDetails(number)
    };

    const GetDuration = (durations) => {
        let duration = Number(durations);
        duration = duration / 1000
        let hours = Math.floor(duration / 3600);
        let minutes = Math.floor((duration % 3600) / 60);
        let seconds = Math.floor(duration % 60);
        let formattedDuration = hours + 'H : ' + minutes + 'M: ' + seconds + "Sec";
        return formattedDuration
    }

    const GetConsoleDetails = async (number) => {
        try {
            let url = "log/GETBuildConsole";
            let method = "post"
            let params = {
                "buildid": number
            }
            let response = await postMethod({ url, authtoken, method, params });
            if (response.status) {
                setJenkinsConsole(response.result)
                console.log("response", response)
                setOpen(true)
            }
        } catch (Error) {
            console.log("Error", Error)
        }
    }
    return (
        <Grid
            container
            lg={12}
            display={"flex"}
            flexDierection={"column"}
            alignItems={"flex-start"}
            justifyContent={"flex-start"}
        >
            <Grid
                container
                lg={12}
                display={"flex"}
                flexDirection={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
                className="mt-4"
            >
                <Grid
                    lg={2.5}
                    display={"flex"}
                    flexDirection={"row"}
                    alignItems={"center"}
                >
                    <h5 className="font-weight-bold">
                        People
                    </h5>
                </Grid>
                <Grid
                    container
                    lg={9.5}
                    display={"flex"}
                    flexDierection={"row"}
                    alignItems={"center"}
                    justifyContent={"flex-end"}
                >
                </Grid>
            </Grid>
            <Grid container lg={12} className="mt-4">
                <TableContainer>
                    <Table
                        //   aria-label="customized table"
                        stickyHeader
                        aria-label="sticky table"
                    >
                        <TableHead className="rounded">
                            <StyledTableRow>
                                <RiskStyledTableCell>User ID</RiskStyledTableCell>
                                <RiskStyledTableCell align="left">
                                    Name{" "}
                                </RiskStyledTableCell>
                                <RiskStyledTableCell align="left">
                                    Last Commit Activity{" "}
                                </RiskStyledTableCell>
                                <RiskStyledTableCell align="left">
                                   On{" "}
                                </RiskStyledTableCell>
                            </StyledTableRow>
                        </TableHead>
                        {!Loader ? (
                            InitialData?.length > 0 ? (
                                <TableBody>
                                    {InitialData?.slice(
                                        page * rowsPerPage,
                                        page * rowsPerPage + rowsPerPage
                                    ).map((row, i) => (
                                        <StyledTableRow key={i}>
                                            <RiskStyledTableCell
                                                align="left"
                                                onClick={() =>
                                                    Typeof === "Clients" ? GetClientDatas(row) : ""
                                                }
                                                sx={{ cursor: "pointer" }}
                                            >
                                                <Grid
                                                    container
                                                    display={"flex"}
                                                    flexDierection={"row"}
                                                    alignItems={"center"}
                                                    justifyContent={"flex-start"}
                                                >
                                                    {/* <Avatar src={row.business_logo} /> */}
                                                    <Grid className="mx-2">
                                                        <p> {row?.user?.fullName}  </p>
                                                    </Grid>
                                                </Grid>
                                            </RiskStyledTableCell>
                                            <RiskStyledTableCell>
                                                <p style={{ cursor: "pointer", color: row.result == "FAILURE" ? "red" : "green" }}>
                                                <p> {row?.user?.fullName}  </p>
                                                </p>
                                            </RiskStyledTableCell>
                                            <RiskStyledTableCell>
                                                <p style={{ cursor: "pointer" }}>
                                                    {row.lastChange && formatedDateAndTime(row.lastChange)}
                                                </p>
                                            </RiskStyledTableCell>
                                            <RiskStyledTableCell>
                                                <p style={{ cursor: "pointer" }}>
                                                    {row?.project?.name}
                                                </p>
                                            </RiskStyledTableCell>
                                          
                                        </StyledTableRow>
                                    ))}
                                </TableBody>
                            ) : (
                                <TableBody>
                                    <StyledTableRow>
                                        <RiskStyledTableCell colSpan={6} align="center">
                                            <p className="text-center">No People Available</p>
                                        </RiskStyledTableCell>
                                    </StyledTableRow>
                                </TableBody>
                            )
                        ) : (
                            <TableBody>
                                <StyledTableRow>
                                    <RiskStyledTableCell colSpan={6} align="center">
                                        <span className="regular">
                                            <CircularProgress style={{ color: "#000" }} />
                                        </span>
                                    </RiskStyledTableCell>
                                </StyledTableRow>
                            </TableBody>
                        )}
                    </Table>

                    {InitialData?.length > 5 && (
                        <Grid
                            container
                            lg={12}
                            xl={12}
                            xs={12}
                            justifyContent={"flex-end"}
                            alignItems={"flex-end"}
                            style={{ backgroundColor: "#FAFAFA" }}
                        >
                            <CustomTablePagination
                                className="paginationintable w-100"
                                component="div"
                                // rowsPerPageOptions={[10, 25, 50, { value: -1, label: "All" }]}
                                count={InitialData.length}
                                page={page}
                                rowsPerPage={rowsPerPage}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                labelRowsPerPage=""
                            />
                        </Grid>
                    )}
                </TableContainer>
            </Grid>

            <ConfirmationModal
                open={Open}
                setOpen={setOpen}
                width={"45%"}
                modalTitle={<h5 className="font-weight-bold">Console Output</h5>}
                modalContent={
                    <Grid
                        container
                        lg={12}
                        display={"flex"}
                        flexDirection={"column"}
                        alignItems={"flex-start"}
                    >
                        <Grid
                            container
                            lg={12}
                            xs={12}
                            className="input_bg mt-2"
                            alignItems="center"
                            justifyContent="flex-end"
                        >
                            <textarea
                                className="Textarea  textAreaHeight"
                                // placeholder="Enter Message here"
                                value={JenkinsConsole}
                                // onChange={(e) => setReason(e.target.value)}
                                // disabled
                                maxLength={2000}
                                rows={9}
                                style={{ backgroundColor: "#F2F2F2", padding: 5 }}
                            />
                        </Grid>
                        <hr className="w-100" />
                        <Grid
                            container
                            lg={12}
                            display={"flex"}
                            flexDirection={"row"}
                            alignItems={"center"}
                            justifyContent={"center"}
                            className="mt-4"
                        >
                            <AuthBtn
                                text={"Download PDF"}
                                btnColor={"#4EAD5B"}
                                btnTextColor="#fff"
                                onClick={() => HandleSideModal(row, i)}
                            />
                            <div className="p-2" />
                            <AuthBtn
                                text={"Send Report to Mail"}
                                btnColor={"#6F98B6"}
                                btnTextColor="#fff"
                                onClick={() => HandleSideModal(row, i)}
                            />
                        </Grid>
                    </Grid>
                }
            />
        </Grid>
    );
};

export default PeopleTable;
