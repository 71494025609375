import { Grid, Paper } from "@mui/material";
import React, { useEffect, useState } from "react";

import JenkinsTable from "./Table";
import PeopleTable from "./Table/PeopleTable";
import { getMethod } from "../../Components/Helper/api"

const Clients = () => {
  const [InquiryType, setInquiryType] = useState("ClientExit");
  const authtoken = localStorage.getItem("@accessToken")
  const [ClientMainDataS , setClientMainDataS]=useState([])
  const [Job_name , setJob_name]=useState("")
  const [LastDate,setLastdate] =useState()
  const [LastFaildDate,setLastFaildDate] =useState()
  const [LastFaildid,setLastFaildid] =useState()
  const [People,setPeople] =useState([])

  useEffect(()=>{
    GetExitRequestData()
    GetPeopleDetails()
  },[])

  const GetExitRequestData = async () => {
    try {
      let url = "log/GetBuildList";
      let method = "GET"
      let response = await getMethod({ url, authtoken, method });
      if (response.status) {
        console.log("GetExitRequestData",response)
        setClientMainDataS(response.result)
        setJob_name(response.job_name)
        setLastdate(response.result[0]?.timestamp)
        response.result.map((build)=>{
            if(build.result=="FAILURE"){
                setLastFaildDate(build.timestamp)
                setLastFaildid(build.number)
            }
        })
      }
    } catch (Error) {
      console.log("GetInqueriryData", Error)
    }
  }
  const GetPeopleDetails = async () => {
    try {
      let url = "log/GetPeopleList";
      let method = "GET"
      let response = await getMethod({ url, authtoken, method });
      if (response.status) {
        console.log("GetPeopleDetails",response.result.users
        )
        setPeople(response.result.users)
      }
    } catch (Error) {
      console.log("GetPeopleDetails", Error)
    }
  }

  const getLatUpdatedate = (lastdate) => {
    if(lastdate){
        let today  = Date.now()
        let difference = Number(today) -  Number(lastdate)
        let days = Math.floor(difference / (1000 * 60 * 60 * 24));
        let hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        let minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
        let formattedDifference = days + 'D : ' + hours + 'H : ' + minutes + 'M';
        return formattedDifference
    }else{
        return ""
    }
  }
  return (
    <Grid
      container
      lg={12}
      display={"flex"}
      flexDirection={"column"}
      alignItems={"center"}
      justifyContent={"center"}
    >
      <Paper className="w-100  p-3">
        <Grid
          container
          lg={12}
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
          justifyContent={"flex-start"}
          className="mt-3"
        >
        </Grid>
        <Grid container lg={12}>
          <PeopleTable ClientsData={ People } Typeof={InquiryType} GetExitRequestData={GetExitRequestData} Job_name={Job_name} />
        </Grid>
        <hr className="w-100 text-muted m-0 p-0" />
        <h5 className="font-weight-bold" style={{textAlign:"start",marginTop:10}} > Build History  - { Job_name }</h5>
        <Grid container style={{padding:10}} lg={12}>
         <Grid lg={6} >
            <p>Last build (# {ClientMainDataS[0]?.number}) {LastDate && getLatUpdatedate(LastDate) }</p>
            <p>Last stable build (#{ClientMainDataS[0]?.number}), {LastDate && getLatUpdatedate(LastDate) } </p>
         </Grid>
         <Grid lg={6} >
            <p>Last successful build (# {ClientMainDataS[0]?.number}), {LastDate && getLatUpdatedate(LastDate) }</p>
            <p>Last failed build (#{LastFaildid}), {getLatUpdatedate(LastFaildDate)} </p>
         </Grid>
        </Grid>
        <hr className="w-100 text-muted m-0 p-0" />
        <Grid container lg={12}>
          <JenkinsTable ClientsData={ ClientMainDataS } Typeof={InquiryType} GetExitRequestData={GetExitRequestData} Job_name={Job_name} />
        </Grid>
      </Paper>
    </Grid>
  );
};

export default Clients;
