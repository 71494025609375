import AWS from "aws-sdk";
import { REACT_APP_BASE_URL1, REACT_APP_AWS_BUCKET_NAME, REACT_APP_AWS_ACCESSKEY_ID, REACT_APP_AWS_SECRET_KEY, REACT_APP_REGION } from "../../../Config"
// Configure AWS SDK with your credentials and region
AWS.config.update({
  accessKeyId: REACT_APP_AWS_ACCESSKEY_ID,
  secretAccessKey: REACT_APP_AWS_SECRET_KEY,
  region: REACT_APP_REGION
});

const s3 = new AWS.S3();
const usersrole = JSON.parse(localStorage.getItem("@users"));
export const getMethod = async (item) => {
  try {
    const response = await fetch(REACT_APP_BASE_URL1 + item.url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-access-token": item.authtoken,
      },
    });
    const json = await response.json();
    if (json.status == false) {
      if (json.message == "Unauthorized!") {
        LogoutUser()
      }
    }
    return json;
  } catch (error) {
    // console.log("get api error", error);
    return false;
  }
};
export const postMethod = async (item) => {
  try {
    const response = await fetch(REACT_APP_BASE_URL1+ item.url, {
      method: item.method,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-access-token": item.authtoken,
      },
      body: JSON.stringify(item.params),
    });
    const json = await response.json();
    if (json.status == false) {
      if (json.message == "Unauthorized!") {
        LogoutUser()
      }
    }
    return json;
  } catch (error) {
    // console.log("post api error", error);
    return false;
  }
};
export const LogoutUser = async () => {
  try {
    let url = "user/logout";
    let method = "PUT"
    let params = {
      "user_email": usersrole.email
    };
    const response = await fetch(REACT_APP_BASE_URL1+ url, {
      method: method,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-access-token": "",
      },
      body: JSON.stringify(params),
    });
    const json = await response.json();
    if (json.status == false) {
      localStorage.clear();
      window.location.replace("/");
    }
  } catch (err) {
    console.log("err", err)
  }
}

export const BackButton = () => {
  window.history.back();
};

export const ColorChanging = (data) => {
  // console.log(data)
  if (data >= 1 && data <= 3) {
    return "backGroundDarkGreen"
  } else if (data >= 4 && data <= 6) {
    return "backGroundLigthGreen "
  } else if (data >= 8 && data <= 12) {
    return "backGroundYellow "
  } else if (data >= 15 && data <= 25) {
    return "backGroundDarkRed"
  } else {
    return "tableBody"
  }
}


export const GetPublicURL = (URL) => {
  try {
    const privateUrl = URL; 
    const urlParts = privateUrl.split("/");
    const Key = urlParts.slice(3).join("/"); 
    let newStr = Key.replace("%2F", "/");
    // https://ohsbuddy.s3.amazonaws.com/OHSBuddy%2FProfile1707978147214.jpg
    const params = {
      Bucket: REACT_APP_AWS_BUCKET_NAME,
      Key: newStr,
      Expires: 3600 *4
    };
    const publicUrl = s3.getSignedUrl("getObject", params);
    return publicUrl
  } catch (Error) {
    console.log("Error in GetPublicURL", Error)
    return ""
  }

}