import React, { useEffect,useState } from "react";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";
import { useDispatch } from "react-redux";

import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import AppRoutes from "./Routes/AppRoutes";
import SideBar from "./Pages/SideBar/Index"
import NetworkModal from "./Components/Modal/NetWorkModal"
import TimeOutModal from "./Components/Modal/TimeOutModal"
import { LogoutUser } from "./Components/Helper/api"
const App = () => {

  const dispatch = useDispatch();
  // const [inactiveTime, setInactiveTime] = useState(0);
  const [TimeModal, setTimeModal] = useState(false);
  const [NetworkStatus, setNetworkStatus] = useState(false);
  const [ActiveTime, setActiveTime] = useState(0)
  var currentTime = 0
  const resetTimer = () => {
    currentTime =0;
  };
  const LogoutData = (data) => {
    if (data == "login") {
      let today = Date.now()
      let diff = ActiveTime - today
      const differenceInSeconds = Math.abs(diff) / 1000
      if (differenceInSeconds > 1) {
        LogoutUser()
      } else {
        setTimeModal(false)
      }
    } else {
      LogoutUser()
    }
  }

  const handleUserActivity = () => {
    resetTimer();
  };
  const handleInactivity = () => {
    // setInactiveTime(prevTime => prevTime + 1);
    currentTime= currentTime+1
    let maxdate = 60* 30 //miniute 
    if (currentTime >= maxdate) {
      if (localStorage.getItem("@login") === "true") {
        setTimeModal(true)
        let date = Date.now()
        setActiveTime(date)
        setTimeout(() => { LogoutData("logout") }, 50000)
        console.log("User inactive for 5 seconds");
      }
    }
    // setActiveTime(0)
  };

  useEffect(() => {
    
    let timer;
    window.addEventListener("mousemove", handleUserActivity);
    window.addEventListener("keydown", handleUserActivity);
    timer = setInterval(handleInactivity, 1000); // Check every second (adjust as needed)
    return () => {
      // Clean up event listeners and timer on component unmount
      window.removeEventListener("mousemove", handleUserActivity);
      window.removeEventListener("keydown", handleUserActivity);
      clearInterval(timer);
    };

  }, []);
  useEffect(() => {
    window.addEventListener("online", () => {
      console.log("You are now online");
      setNetworkStatus(false)
    });

    window.addEventListener("offline", () => {
      console.log("You are now offline");
      setNetworkStatus(true)
    });

    if (navigator.onLine) {
      console.log("The browser is currently online.");
    } else {
      console.log("No internet connection.");
    }
    requestForToken()
  }, []);

  useEffect(() => {
    console.log("localStorage.getItem ", localStorage.getItem("@login"))
  }, [])

  var firebaseConfig = {
    apiKey: "AIzaSyCXFndtHx4_6OKR-0czGLvnrzPmmf0RuOY",
    authDomain: "ohsbuddy-977f3.firebaseapp.com",
    projectId: "ohsbuddy-977f3",
    storageBucket: "ohsbuddy-977f3.appspot.com",
    messagingSenderId: "671202255544",
    appId: "1:671202255544:web:7bc645352f4a71d6418a4d",
    measurementId: "G-YPMV0C5YCN"
  };
  initializeApp(firebaseConfig);
  // Retrieve firebase messaging
  const messaging = getMessaging();
  const requestForToken = () => {
    return getToken(messaging, { vapidKey: "BIc4TKrfH8JACe031qHQVuww6-S1-nLOXc--qpyU-1u4R2ScichadnlU9vk1niUEL9-nzrTKnPrHA0xBHp1sn3s" })
      .then((currentToken) => {
        if (currentToken) {
          dispatch({
            type: "FCM_TOKEN",
            payload: { FCMToken: currentToken }
          })
          console.log("current token for client: ", currentToken);
        } else {
          console.log("No registration token available. Request permission to generate one.");
        }
      })
      .catch((err) => {
        console.log("An error occurred while retrieving token. ", err);
      });
  };

  useEffect(() => {
    requestForToken()
  }, [])

  return (
    <div className="App">
      {localStorage.getItem("@login") === "true" ? <SideBar /> : <AppRoutes />}
      <ToastContainer
        position="bottom-right"
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        toastStyle={{
          backgroundColor: "#fff",
          fontFamily: "InterMedium",
          fontSize: "13px",
          textAlign: "left",
          color: "#000",
        }}
      />
      <TimeOutModal open={TimeModal} handleClose={setTimeModal} Logout={LogoutData} />
      <NetworkModal open={NetworkStatus} handleClose={setNetworkStatus} />
    </div>
  );
};

export default App;
