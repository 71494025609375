import { Avatar, CircularProgress, Grid, Paper } from "@mui/material";
import React, { useEffect, useState } from "react";
import { CiSearch } from "react-icons/ci";
import { BiSolidFilePdf } from "react-icons/bi";
import { FaFileCsv } from "react-icons/fa";
import { IoReloadOutline } from "react-icons/io5";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import { IoMdAttach } from "react-icons/io";
import { toast } from "react-toastify";
import { FaDotCircle } from "react-icons/fa";
import AWS from "aws-sdk";
import { AiFillCloseCircle } from "react-icons/ai";
import { BsStopwatch } from "react-icons/bs";

import {
  RiskStyledTableCell,
  StyledTableRow,
  CustomTablePagination,
} from "../../../Components/materialTableStyle/StyleTableCell";
import RightSideModal from "../../../Components/Modal/RightSideModal";
// import { TicketCategory } from "../Data/TicketsData";
import ImagePreviewModal from "../../../Components/Modal/ImagePreview";
import AuthBtn from "../../../Components/Button/AuthBtn";
import ConfirmationModal from "../../../Components/Modal/Modal";
import CustomTooltip from "../../../Components/Input/CustomToolTip";
import { FormatDateOnly, FormatDate, FormatTimeOnly } from "../../../Components/DateFormats/DateFromat";
import CustomDropDown from "../../../Components/DropDown/DropDown";
import DatePicker from "../../../Components/DateFormats/Datepicker";
import CustomInput from "../../../Components/Input/CustomInput";
import { getMethod, postMethod,GetPublicURL } from "../../../Components/Helper/api";
import { REACT_APP_AWS_ACCESSKEY_ID, REACT_APP_AWS_SECRET_KEY, REACT_APP_AWS_BUCKET_NAME, REACT_APP_REGION } from "../../../Config"
import { csvDownload } from "../../../Components/CSVDownload/DownloadCSV"
const TicketsHomeTable = ({ TicketsData, GetTicketList }) => {
  const Admin = JSON.parse(localStorage.getItem("@users"))
  const authtoken = localStorage.getItem("@accessToken")

  const [InitialData, setInitialData] = useState([]);
  const [FilterDataData, setFilterDataData] = useState([]);
  const [Loader, setLoader] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [ViewTicketModal, setViewTicketModal] = useState(false);
  const [SelectedData, setSelectedData] = useState("");
  const [SelectedIndex, setSelectedIndex] = useState(0);
  const [ViewImage, setViewImage] = useState(false);
  const [SelectedImage, setSelectedImage] = useState("");
  const [ReplyMessage, setReplyMessage] = useState("");
  const [Reason, setReason] = useState("");
  const [CloseMessage, setCloseMessage] = useState("");
  const [RejectedModal, setRejectedModal] = useState(false);
  const [MaintenanceModal, setMaintenanceModal] = useState(false);
  const [ApproveModal, setApproveModal] = useState(false);
  const [ClosingModal, setClosingModal] = useState(false);
  const [Priority, setPriority] = useState("");
  const [SelectedStatus, setSelectedStatus] = useState("");
  const [fromDate, setFromDate] = useState();
  const [timeout, settimeout] = useState(false);
  const [RejectLoader, setRejectLoader] = useState(false);
  const [ApproveLoader, setApproveLoader] = useState(false);
  const [TicketCategory, setTicketCategory] = useState([]);

  // const [FileuploadLink, setFileuploadLink] = useState("");
  // const [Fileupload, setFileupload] = useState();
  const [FileLoader, setFileLoader] = useState(false);
  const [PDFLoader, setPDFLoader] = useState(false);

  const [toDate, setToDate] = useState();
  const [SearchQuery, setSearchQuery] = useState("");
  const [closeAttachments, setcloseAttachments] = useState([])
  useEffect(() => {
    setInitialData(TicketsData);
    setFilterDataData(TicketsData)
    setLoader(true);
    setcloseAttachments([])
  }, [TicketsData]);

  useEffect(() => {
    GetTicketCategory()
  }, [])

  useEffect(() => {
    if (Loader) {
      setTimeout(() => {
        setLoader(false);
      }, 1000);
    }
  }, [Loader]);

  const GetTicketCategory = async () => {
    try {
      let url = "category/List"
      let method = "GET"
      const response = await getMethod({ url, method, authtoken })
      if (response.status) {
        let array = []
        response.result?.map((ticket) => {
          array.push({ label: ticket.ticket_category_name, value: ticket.ticket_category_name })
        })
        setTicketCategory(array)
      }
    } catch (Error) {
      console.log("GetTicketCategory Error ", Error)
    }
  }
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const DeleteCloseFile = (index) => {
    const data = [...closeAttachments]
    data.splice(index, 1)
    setcloseAttachments(data)

  }
  const ViewTicket = (data, i) => {
    setSelectedData(data);
    setSelectedIndex(i);
    setViewTicketModal(true);

    console.log(SelectedData, SelectedIndex);
  };

  const uploadFile = async (files) => {
    try {
      if (files) {
        const myArray = Object.values(files);
        if (myArray.length > 5) {
          toast.error("Maximum you can Upload 5 files");
          return;
        }
        setFileLoader(true)
        Promise.all(await myArray?.map(async (file, i) => {
          let name = file.name;
          let maxSizeInBytes = 3145728; // 3 MB in bytes (3 * 1024 * 1024);
          if (file.size > maxSizeInBytes) {
            setFileLoader(false)
            toast.error("Please Upload a File Below 3 Mb");
            return;
          }

          let extension = "." + name.split(".").pop(); //jpg,jpeg,png
          let filename = "Document" + "_" + Date.now() + extension;
          const s3 = new AWS.S3({
            accessKeyId: REACT_APP_AWS_ACCESSKEY_ID,
            secretAccessKey: REACT_APP_AWS_SECRET_KEY,
            region: REACT_APP_REGION
          });
          const params = {
            Bucket: REACT_APP_AWS_BUCKET_NAME,
            Key: filename,
            Body: file
          };
          const data = await s3.upload(params).promise();
          if (data.Location) {
            closeAttachments.push({ file: data.Location, filename: filename })
            // setFileuploadLink(data.Location);
            // setFileupload(filename);
            if (i == myArray.length - 1) {
              setFileLoader(false)
            }
          }
        }))
        console.log("NewArray", closeAttachments)
      }
    }
    catch (err) {
      setFileLoader(false)
      console.log("err in uploadfile", err);
    }
  };
  //Date picker
  const handleDateApply = (picker) => {
    settimeout(false);
    setFromDate(picker?.startDate?.toString());
    setToDate(picker?.endDate?.toString());
    const StartDate = new Date(picker.startDate).getTime();
    const EndDate = new Date(picker.endDate).getTime();
    const enddate = EndDate + 86400000;
    console.log("StartDate", StartDate, enddate);
    handleDateChange(StartDate, enddate);
  };


  const handleDateChange = (fromDate, toDate) => {
    if (fromDate != undefined) {
      console.log("TicketsData", TicketsData?.length)
      const displayedData_info = TicketsData.filter(
        (item) =>
          Number(item.ticket_created_at) >= fromDate && Number(item.ticket_created_at) <= toDate
      );
      setInitialData(displayedData_info);
    }
  };

  const ByTicketPriority = [
    {
      label: "Low",
      value: "Low",
    },
    {
      label: "Moderate",
      value: "Moderate",
    },
    {
      label: "High",
      value: "High",
    },
  ];
  const FilterByTicketPriority = [
    {
      label: "Low",
      value: "Low",
    },
    {
      label: "Moderate",
      value: "Moderate",
    },
    {
      label: "High",
      value: "High",
    },
    {
      label: "All",
      value: "All",
    }
  ];

  const FilterByTicketStatus = [
    { label: "Pending", value: "Pending" },
    { label: "Closed", value: "Closed" },
    { label: "Open", value: "Open" },
    { label: "Rejected", value: "Rejected" },
    { label: "All", value: "All" },
  ];

  const Preview = (data) => {
   let URL = GetPublicURL(data)
    setSelectedImage(URL);
    setViewImage(true);
  };

  const RejectValidation = async () => {
    if (!SelectedData?.ticket_id) {
      toast.error("Please try Again ");
      return;
    }
    if (Reason.trim().length === 0) {
      toast.error("Please enter the Reason ");
      return;
    }
    if (Reason.length < 20) {
      toast.error("Please enter the reason atleast 50 characters");
      return;
    }
    try {
      let method = "POST"
      let url = "ticket/RejectTicket"
      let params = {
        ticket_id: SelectedData?.ticket_id,
        admin_email: Admin.email,
        reason: Reason.trim()
      }
      setRejectLoader(true)
      const response = await postMethod({ method, url, authtoken, params })
      if (response.status) {
        GetTicketList()
        setRejectLoader(false)
        toast.success("Rejected successfully");
        var Data = [...InitialData];
        Data[SelectedIndex]["Status"] = "Rejected";
        setReason("");
        setRejectedModal(false);
        setViewTicketModal(false);
        setInitialData(Data);
        setLoader(true);
      } else {
        setRejectLoader(false)
        if (response.message) {
          toast.error(response.message)
        } else {
          toast.error("Something went wrong")
        }
      }
    } catch (Error) {
      setRejectLoader(false)
      toast.error("Something went wrong")
    }

  };

  const ValidApprove = () => {
    if (!SelectedData?.ticket_id) {
      toast.error("Please Try Again");
      return;
    }
    if (ReplyMessage.trim().length === 0) {
      toast.error("Please enter your reply message");
      return;
    }
    if (ReplyMessage.trim().length < 50) {
      toast.error("Your reply message should be max 50 characters");
      return;
    }
    if (!SelectedData?.ticket_priority) {
      toast.warn("Select a ticket priority");
      return;
    }
    setApproveModal(true);
  };

  const ApproveConfirmed = async () => {
    try {
      let method = "POST"
      let url = "ticket/ApproveTicket"
      let params = {
        ticket_id: SelectedData?.ticket_id,
        admin_email: Admin.email,
        response: ReplyMessage.trim(),
        priority: SelectedData?.ticket_priority ? SelectedData?.ticket_priority : "Low",
        maintanance: false
      }
      setApproveLoader(true)
      const response = await postMethod({ method, url, authtoken, params })
      if (response.status) {
        GetTicketList()
        setApproveLoader(false)
        var updatedDate = FormatDateOnly(new Date().getTime());
        const OldArr = [...InitialData];
        OldArr[SelectedIndex]["Status"] = "Open";
        OldArr[SelectedIndex]["LastUpdated"] = updatedDate;
        setInitialData(OldArr);
        setApproveModal(false);
        setViewTicketModal(false);
        setReplyMessage("");
        toast.success("Ticket has been approved successfully");
      } else {
        setApproveLoader(false)
        if (response.message) {
          toast.error(response.message)
        } else {
          toast.error("Something went wrong")
        }
      }
    } catch (Error) {
      setApproveLoader(false)
      toast.error("Something went wrong")
      console.log("ApproveConfirmed Errot", Error)
    }

  };

  const ForwardConfirmed = async () => {
    if (!SelectedData.ticket_id) {
      toast.error("Try Again Later")
      return
    }
    try {
      let method = "POST"
      let url = "ticket/ApproveTicket"
      let params = {
        ticket_id: SelectedData?.ticket_id,
        admin_email: Admin.email,
        response: "Forward",
        priority: "High",
        maintanance: true
      }
      setApproveLoader(true)
      const response = await postMethod({ method, url, authtoken, params })
      if (response.status) {
        GetTicketList()
        setApproveLoader(false)
        var updatedDate = FormatDateOnly(new Date().getTime());
        const OldArr = [...InitialData];
        OldArr[SelectedIndex]["Status"] = "Open";
        OldArr[SelectedIndex]["LastUpdated"] = updatedDate;
        setInitialData(OldArr);
        setApproveModal(false);
        setViewTicketModal(false);
        setMaintenanceModal(false)
        setReplyMessage("");
        toast.success("Ticket has been Forward to maintenance team successfully");
      } else {
        setApproveLoader(false)
        if (response.message) {
          toast.error(response.message)
        } else {
          toast.error("Something went wrong")
        }
      }
    } catch (Error) {
      setApproveLoader(false)
      toast.error("Something went wrong")
      console.log("ApproveConfirmed Errot", Error)
    }

  };

  const CloseTicketModal = () => {
    if (!SelectedData?.ticket_id) {
      toast.warn("Please try again later");
      return;
    }
    if (CloseMessage.trim().length === 0) {
      toast.warn("Please enter  your close message");
      return;
    }
    if (CloseMessage.trim().length < 50) {
      toast.warn("your close message should be max 50 characters");
      return;
    }
    if (!SelectedData?.ticket_priority) {
      toast.warn("Select a ticket priority");
      return;
    }
    setClosingModal(true);
  };

  const CloseConfirmed = async () => {
    
    try {
      if (SelectedData?.exit_request) {
        console.log("maintenance_status", SelectedData)
        let url = "delete/CompleteRequest";
        let method = "PUT"
        let params = {
          ticket_id: SelectedData?.ticket_id,
          message: CloseMessage.trim(),
          request_id: SelectedData?.exit_request_id,
          admin_email: Admin.email,
          tenants_id: SelectedData?.tenants_id,
          attachemnt: closeAttachments
        }
        setApproveLoader(true)
        let response = await postMethod({ url, authtoken, method, params });
        if(response.status){
          GetTicketList()
          var updatedDate = FormatDateOnly(new Date().getTime());
          const OldArr = [...InitialData];
          OldArr[SelectedIndex]["Status"] = "Closed";
          OldArr[SelectedIndex]["LastUpdated"] = updatedDate;
          setInitialData(OldArr);
          setClosingModal(false);
          setViewTicketModal(false);
          setCloseMessage("");
          toast.success("Ticket has been Closed successfully");
          setApproveLoader(false)
        }else{
          console.log("response",response)
          setApproveLoader(false)
          if (response.message) {
            toast.error(response.message)
          } else {
            toast.error("something went wrong")
          }
        }
      } else {
        let method = "POST"
        let url = "ticket/CloseTicket"
        let params = {
          ticket_id: SelectedData?.ticket_id,
          admin_email: Admin.email,
          message: CloseMessage.trim(),
          priority: SelectedData?.ticket_priority ? SelectedData?.ticket_priority : "Low",
          attachemnt: closeAttachments // [FileuploadLink]
        }
        setApproveLoader(true)
        const response = await postMethod({ method, url, authtoken, params })
        if (response.status) {
          GetTicketList()
          var updatedDate = FormatDateOnly(new Date().getTime());
          const OldArr = [...InitialData];
          OldArr[SelectedIndex]["Status"] = "Closed";
          OldArr[SelectedIndex]["LastUpdated"] = updatedDate;
          setInitialData(OldArr);
          setClosingModal(false);
          setViewTicketModal(false);
          setCloseMessage("");
          toast.success("Ticket has been Closed successfully");
          setApproveLoader(false)
        } else {
          setApproveLoader(false)
          if (response.message) {
            toast.error(response.message)
          } else {
            toast.error("something went wrong")
          }
        }
      }

    } catch (Error) {
      setApproveLoader(false)
      toast.error("something went wrong")
      console.log("CloseConfirmed Error", Error)
    }
  };

  const UpdatedTicket = (name, data) => {
    const OldArr = [...InitialData];
    OldArr[SelectedIndex][name] = data;
    // setInitialData(OldArr);
    let datas = SelectedData
    datas[name] = data
    console.log(" OldArr[SelectedIndex]", OldArr[SelectedIndex])
    setSelectedData(datas)
  };

  const handleFilterByTicketStatus = (item) => {
    setSelectedStatus(item);
    const OldArr = [...FilterDataData];
    console.log("OldArr", OldArr.length)
    if (item.value === "All") {
      setInitialData(OldArr);
    } else {
      const FilterData = OldArr.filter((data) => data.ticket_update_status?.toLowerCase() == item.value?.toLowerCase());
      setInitialData(FilterData);
    }
  };

  const HandleFilterBypriority = (item) => {
    setPriority(item);
    console.log("item", item)
    console.log("FilterDataData", FilterDataData)
    const OldArr = [...FilterDataData];
    if (item.value === "All") {
      setInitialData(OldArr);
    } else {
      const FilterData = OldArr.filter((data) => String(data.ticket_priority).toLowerCase() === String(item.value).toLowerCase())
      setInitialData(FilterData);
    }
  };


  const HandleSearch = (e) => {
    const Datas = [...TicketsData];
    setSearchQuery(e);
    let filetedsearch = Datas.filter(
      (a) =>
        a.company_name?.toLowerCase().includes(e?.toLowerCase()) ||
        a.tenant_email?.toLowerCase().includes(e?.toLowerCase()) ||
        // a.phone_number?.toLowerCase().includes(e?.toLowerCase()) ||
        a.register_address?.toLowerCase().includes(e?.toLowerCase()) ||
        a.ticket_priority?.toLowerCase().includes(e?.toLowerCase()) ||
        a.ticket_serial_id?.toLowerCase().includes(e?.toLowerCase()) ||
        a.ticket_category?.toLowerCase().includes(e?.toLowerCase())
    );
    setFilterDataData(filetedsearch)
    setInitialData(filetedsearch);
  };

  const RefreshData = () => {
    setSearchQuery("")
    GetTicketList()
    setLoader(true);
    setInitialData(TicketsData);
    setPriority("");
    setSelectedStatus("");
  };
  const DownloadCSV = () => {
    let arr = []
    InitialData.map((item, i) => {
      arr.push([
        i + 1,
        item.ticket_serial_id,
        item.company_name,
        item.ticket_category,
        item.ticket_priority,
        item.ticket_update_status ? item.ticket_update_status?.toUpperCase() : "--",
        FormatDate(item.ticket_created_at),
        item.ticket_updated_on ? FormatDate(item.ticket_updated_on) : "---",
      ])
    })
    const csvheaderData = [
      ["Report Name", "Ticket Helpdesk"],
      ["Report Genetated On: ", FormatDate(Date.now())],
      ["Sno", "Ticket ID", "Client Name", "Ticket Category", "Priority", "Status", "Created Date", "Last Updated"],
      ...arr
    ]
    csvDownload(csvheaderData, "Ticket Helpdesk");
  }
  const DownloadPDF = async () => {
    try {
      let url = "reports/TicketsPFD";
      let method = "POST"
      let params = {
        admin_email: Admin.email,
      }
      setPDFLoader(true)
      let response = await postMethod({ url, authtoken, method, params });
      if (response.status) {
        const s3Url = response.result;
        const pdfBlob = await fetch(s3Url).then(response => response.blob());
        const temporaryUrl = URL.createObjectURL(pdfBlob);
        const link = document.createElement("a");
        link.href = temporaryUrl;
        link.target = "_blank";
        link.download = "Tickets.pdf";
        document.body.appendChild(link);
        link.click();
        URL.revokeObjectURL(temporaryUrl);
        document.body.removeChild(link);
        setPDFLoader(false)
        toast.success("Pdf file created successfully");
      } else {
        if (response.message) {
          toast.error(response.message);
        } else {
          toast.error("Something went wrong")
        }
        setPDFLoader(false)
      }
    } catch (Error) {
      setPDFLoader(false)
      console.log("Error", Error)
      toast.error("Something went wrong")

    }
  }
  const calculateDateDifference = (end, start) => {
    var diffInMilliseconds = Number(start) - Number(end);
    diffInMilliseconds = Math.abs(diffInMilliseconds)
    const days = Math.floor(diffInMilliseconds / (24 * 60 * 60 * 1000));
    const hours = Math.floor((diffInMilliseconds % (24 * 60 * 60 * 1000)) / (60 * 60 * 1000));
    const minutes = Math.floor((diffInMilliseconds % (60 * 60 * 1000)) / (60 * 1000));

    return `${days}D ${hours}H ${minutes}M`;
  };
  return (
    <Grid
      container
      lg={12}
      display={"flex"}
      alignItems={"flex-start"}
      justifyContent={"flex-start"}
    >
      <Paper className="p-3 w-100">
        <Grid
          container
          lg={12}
          display={"flex"}
          flexDirection={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
          className="mt-3"
        >
          <Grid
            lg={2.5}
            display={"flex"}
            flexDirection={"row"}
            alignItems={"center"}
          >
            <h5 className="font-weight-bold">
              Ticket Helpdesk - {InitialData?.length}
            </h5>
          </Grid>
          <Grid
            container
            lg={9.5}
            display={"flex"}
            flexDierection={"row"}
            alignItems={"center"}
            justifyContent={"flex-end"}
          >
            <Grid item lg={2.5} className="p-2">
              <CustomInput
                placeholder={"Search.."}
                Value={SearchQuery}
                StartIcon={<CiSearch size={"20px"} />}
                height={"40px"}
                handleInput={(e) => HandleSearch(e)}
              />
            </Grid>
            <Grid
              item
              className=" p-2"
              display={"flex"}
              alignItems={"center"}
              justifyContent={"flex-end"}
            >
              <DatePicker
                setFromDate={fromDate}
                setToDate={toDate}
                OnDateApply={handleDateApply}
                timeout={timeout}
              />
            </Grid>
            <Grid
              item
              lg={2.8}
              className="p-2"
              display={"flex"}
              alignItems={"center"}
              justifyContent={"flex-end"}
            >
              <CustomDropDown
                data={FilterByTicketPriority}
                placeholder={"Priority"}
                selected={Priority}
                handleSelected={(item) => HandleFilterBypriority(item)}
              />
            </Grid>
            <Grid
              item
              lg={2.2}
              className="p-2"
              display={"flex"}
              alignItems={"center"}
              justifyContent={"flex-end"}
            >
              <CustomDropDown
                data={FilterByTicketStatus}
                placeholder={"Status"}
                selected={SelectedStatus}
                handleSelected={(item) => handleFilterByTicketStatus(item)}
              />
            </Grid>
            <Grid
              item
              lg={2}
              display={"flex"}
              flexDirection={"row"}
              alignItems={"center"}
              justifyContent={"flex-end"}
            >
              <button disabled={PDFLoader} style={{ alignContent: "center", alignItems: "center", display: "flex", maxHeight: "20%", height: "38px" }} onClick={() => DownloadPDF()} className="btn btn-light  border rounded">
                {PDFLoader ? <CircularProgress size={20} style={{ color: "#000", alignSelf: "center", alignItems: "center" }} /> : <BiSolidFilePdf size={"24px"} color="#E93322" />}
              </button>
              <div className="p-1" />
              <button onClick={() => DownloadCSV()} className="btn btn-light  border rounded">
                <FaFileCsv size={"22px"} color="#23C552" />
              </button>
              <div className="p-1" />
              <button
                className="btn btn-light  border rounded"
                onClick={() => RefreshData()}
              >
                <p>
                  <IoReloadOutline
                    size={"22px"}
                    style={{ fontWeight: "bolder" }}
                    color="#0183FD"
                  />{" "}
                </p>
              </button>
            </Grid>
          </Grid>
        </Grid>
        <Grid container lg={12} className="mt-4">
          <TableContainer>
            <Table
              //   aria-label="customized table"
              stickyHeader
              aria-label="sticky table"
            >
              <TableHead className="rounded">
                <StyledTableRow>
                  <RiskStyledTableCell>Ticket ID</RiskStyledTableCell>
                  <RiskStyledTableCell>Client Name</RiskStyledTableCell>
                  <RiskStyledTableCell align="left">
                    Ticket Category
                  </RiskStyledTableCell>
                  <RiskStyledTableCell align="left" sx={{ justifyContent: "space-around" }} >
                    <BsStopwatch />  Timer
                  </RiskStyledTableCell>
                  <RiskStyledTableCell>Priority</RiskStyledTableCell>
                  <RiskStyledTableCell>Status</RiskStyledTableCell>
                  <RiskStyledTableCell>Created Date</RiskStyledTableCell>
                  <RiskStyledTableCell>Last Updated</RiskStyledTableCell>
                </StyledTableRow>
              </TableHead>
              {!Loader ? (
                InitialData?.length > 0 ? (
                  <TableBody>
                    {InitialData?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    ).map((row, i) => (
                      <StyledTableRow key={i}>
                        <RiskStyledTableCell
                          sx={{ cursor: "pointer" }}
                          onClick={() => ViewTicket(row, i)}
                        >
                          <p className="text-primary">{row.ticket_serial_id}</p>
                        </RiskStyledTableCell>
                        <RiskStyledTableCell
                          align="left"
                          sx={{ cursor: "pointer" }}
                        >
                          <Grid
                            container
                            display={"flex"}
                            flexDierection={"row"}
                            alignItems={"center"}
                            justifyContent={"flex-start"}
                          >
                            <Avatar src={GetPublicURL(row.business_logo)} />
                            <Grid className="mx-2">
                              <CustomTooltip
                                title={row.company_name}
                                placement="right"
                              >
                                <p>
                                  {row?.company_name?.length > 20
                                    ? row.company_name.slice(0, 20) + "..."
                                    : row?.company_name}
                                </p>
                              </CustomTooltip>
                              <CustomTooltip
                                title={row.register_address}
                                placement="right"
                              >
                                <p className="text-muted">
                                  {row.register_address?.length > 20
                                    ? row.register_address.slice(0, 20) + "..."
                                    : row?.register_address
                                  }
                                </p>
                              </CustomTooltip>
                            </Grid>
                          </Grid>
                        </RiskStyledTableCell>
                        <RiskStyledTableCell>
                          <p>{row.ticket_category}</p>
                        </RiskStyledTableCell>
                        <RiskStyledTableCell>
                          <p>{row.ticket_updated_on ? calculateDateDifference(row.ticket_updated_on, row.ticket_created_at) : "---"}</p>
                        </RiskStyledTableCell>
                        <RiskStyledTableCell>
                          {row.ticket_priority ? <p style={{textTransform:"capitalize"}} >
                            {" "}
                            <FaDotCircle
                              className="mx-1"
                              color={
                                row.ticket_priority === "Low"
                                  ? "#23C552"
                                  : row.ticket_priority === "Moderate"
                                    ? "#0183FD"
                                    : "#FF955A"
                              }
                            />{" "}
                            {row.ticket_priority}
                          </p> : <p>---</p>}
                        </RiskStyledTableCell>
                        <RiskStyledTableCell>
                          <p style={{ textTransform: "capitalize" }}>
                            {" "}
                            <FaDotCircle
                              className="mx-1"
                              color={
                                row.ticket_update_status === "open"
                                  ? "#23C552" : row.ticket_update_status === "approved"
                                    ? "#23C552"
                                    : row.ticket_update_status === "rejected"
                                      ? "#E93322" :
                                      row.ticket_update_status === "closed"
                                        ? "#FF955A"
                                        : "#F1DF5B"
                              }
                            />{" "}
                            {row.ticket_update_status}
                          </p>
                        </RiskStyledTableCell>
                        <RiskStyledTableCell>
                          <p>{FormatDateOnly(row.ticket_created_at)}</p>
                          <p className="text-muted" >{row.ticket_created_at ? FormatTimeOnly(row.ticket_created_at) : "----"}</p>
                        </RiskStyledTableCell>
                        <RiskStyledTableCell align="left">
                          <p>{row.ticket_updated_on ? FormatDateOnly(row.ticket_updated_on) : "----"}</p>
                          <p className="text-muted" >{row.ticket_updated_on ? FormatTimeOnly(row.ticket_updated_on) : "----"}</p>
                        </RiskStyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                ) : (
                  <TableBody>
                    <StyledTableRow>
                      <RiskStyledTableCell colSpan={7} align="center">
                        <p className="text-center">No Tickets Available</p>
                      </RiskStyledTableCell>
                    </StyledTableRow>
                  </TableBody>
                )
              ) : (
                <TableBody>
                  <StyledTableRow>
                    <RiskStyledTableCell colSpan={7} align="center">
                      <span className="regular">
                        <CircularProgress style={{ color: "#000" }} />
                      </span>
                    </RiskStyledTableCell>
                  </StyledTableRow>
                </TableBody>
              )}
            </Table>

            {InitialData?.length > 10 && (
              <Grid
                container
                lg={12}
                xl={12}
                xs={12}
                justifyContent={"flex-end"}
                alignItems={"flex-end"}
                style={{ backgroundColor: "#FAFAFA" }}
              >
                <CustomTablePagination
                  className="paginationintable w-100"
                  component="div"
                  rowsPerPageOptions={[10, 25, 50, { value: -1, label: "All" }]}
                  count={InitialData.length}
                  page={page}
                  rowsPerPage={rowsPerPage}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  labelRowsPerPage=""
                />
              </Grid>
            )}
          </TableContainer>
        </Grid>
      </Paper>
      <RightSideModal
        open={ViewTicketModal}
        setOpen={setViewTicketModal}
        height={"100%"}
        width={"40%"}
        scroll={true}
        modalTitle={<h5 className="font-weight-bold"> Ticket Helpdesk</h5>}
        modalContent={
          <Grid
            container
            lg={12}
            display={"flex"}
            flexDierection={"column"}
            alignItems={"flex-start"}
            justifyContent={"flex-start"}
          >
            <hr className="w-100 mt-1" />
            <Grid
              container
              lg={12}
              display={"flex"}
              alignItems={"center"}
              className="p-2"
            >
              <Grid xs={4}>
                <p className="text-muted">Ticket ID</p>
              </Grid>
              <Grid xs={6}>
                <p className="text-primary"> {SelectedData?.ticket_serial_id}</p>
              </Grid>
            </Grid>
            <Grid
              container
              lg={12}
              display={"flex"}
              alignItems={"center"}
              className="p-2"
            >
              <Grid xs={4}>
                <p className="text-muted">Company Name</p>
              </Grid>
              <Grid xs={6}>
                <p> {SelectedData?.company_name}</p>
              </Grid>
            </Grid>
            <Grid
              container
              lg={12}
              display={"flex"}
              alignItems={"center"}
              className="p-2"
            >
              <Grid xs={4}>
                <p className="text-muted">Ticket Category</p>
              </Grid>
              {SelectedData.ticket_update_status != "open" && SelectedData.ticket_update_status != "closed" ?
                <Grid xs={6}>
                  <CustomDropDown
                    data={TicketCategory}
                    selected={{
                      label: SelectedData?.ticket_category,
                      value: SelectedData?.ticket_category,
                    }}
                    handleSelected={(data) =>
                      UpdatedTicket("ticket_category", data.value)
                    }
                  />
                </Grid>
                :
                <p style={{ textTransform: "capitalize" }}> {SelectedData?.ticket_category}</p>
              }

            </Grid>
            <Grid
              container
              lg={12}
              display={"flex"}
              alignItems={"center"}
              className="p-2"
            >
              <Grid xs={4}>
                <p className="text-muted">Priority</p>
              </Grid>
              {SelectedData.ticket_update_status != "open" && SelectedData.ticket_update_status != "closed" ? <Grid xs={6}>
                <CustomDropDown
                  data={ByTicketPriority}
                  selected={{
                    label: SelectedData?.ticket_priority,
                    value: SelectedData?.ticket_priority,
                  }}
                  handleSelected={(data) =>
                    UpdatedTicket("ticket_priority", data.value)
                  }
                />
              </Grid>
                : <p style={{ textTransform: "capitalize" }}>
                  {""}
                  <FaDotCircle
                    className="mx-1"
                    color={
                      SelectedData.ticket_priority === "Low"
                        ? "#23C552"
                        : SelectedData.ticket_priority === "Moderate"
                          ? "#0183FD"
                          : "#FF955A"
                    }
                  />
                  {SelectedData?.ticket_priority}</p>
              }
            </Grid>
            <Grid
              container
              lg={12}
              display={"flex"}
              alignItems={"center"}
              className="p-2"
            >
              <Grid xs={4}>
                <p className="text-muted">Status</p>
              </Grid>
              <Grid xs={6}>
                <p style={{ textTransform: "capitalize" }}>
                  {" "}
                  <FaDotCircle
                    className="mx-1"
                    color={
                      SelectedData.ticket_update_status === "open"
                        ? "#23C552"
                        : SelectedData.ticket_update_status === "rejected"
                          ? "#E93322"
                          : "#F1DF5B"
                    }
                  />
                  {SelectedData.ticket_update_status}
                </p>
              </Grid>
            </Grid>
            <Grid
              container
              lg={12}
              display={"flex"}
              alignItems={"center"}
              className="p-2"
            >
              <Grid xs={4}>
                <p className="text-muted">Created Date</p>
              </Grid>
              <Grid xs={6}>
                <p style={{ textTransform: "capitalize" }}>
                  {" "}
                  {FormatDate(SelectedData.ticket_created_at)}
                </p>
              </Grid>
            </Grid>
            <Grid
              container
              lg={12}
              display={"flex"}
              alignItems={"center"}
              className="p-2"
            >
              <Grid xs={4}>
                <p className="text-muted">Last Updated</p>
              </Grid>
              <Grid xs={6}>
                <p style={{ textTransform: "capitalize" }}>
                  {" "}
                  {SelectedData.ticket_updated_on ? FormatDate(SelectedData.ticket_updated_on) : "---"}
                </p>
              </Grid>
            </Grid>
            <h6 className="font-weight-bold mx-2"> Message </h6>
            <Grid lg={12} className="p-2">
              <p className="border p-2 rounded">
                {" "}
                {SelectedData?.ticket_description}
              </p>
            </Grid>

            {SelectedData?.ticket_attachments?.values?.length > 0 && (
              <>
                <h6 className="font-weight-bold mt-3 mx-2">
                  {" "}
                  Attachments from User{" "}
                </h6>
                <Grid
                  container
                  lg={12}
                  display={"flex"}
                  flexDirection={"row"}
                  alignItems={"strecth"}
                  justifyContent={"flex-start"}
                  className="p-2"
                >
                  {SelectedData?.ticket_attachments?.values.map((row, i) => (
                    <Grid
                      item
                      key={i}
                      className="p-1 mt-2"
                      sx={{ cursor: "pointer" }}
                      onClick={() => Preview(row)}
                    >
                      <img
                        className="rounded mx-auto"
                        src={GetPublicURL(row)}
                        alt="Attachments"
                        style={{ height: "100px", width: "100px" }}
                      />
                    </Grid>
                  ))}
                </Grid>
              </>
            )}
            {SelectedData?.ticket_update_status == "pending" ? (
              <Grid
                container
                lg={12}
                display={"flex"}
                alignItems={"flex-start"}
                justifyContent={"flex-start"}
                className="mt-3"
              >
                <h6 className="font-weight-bold mx-2">Reply Message</h6>
                <Grid container lg={12} xs={12} className="p-2">
                  <Grid
                    container
                    lg={12}
                    xs={12}
                    className="input_bg"
                    alignItems="center"
                    justifyContent="flex-end"
                  >
                    <textarea
                      className="Textarea  textAreaHeight"
                      placeholder="Enter Message here"
                      value={ReplyMessage}
                      onChange={(e) => setReplyMessage(e.target.value)}
                      maxLength={2000}
                      rows={6}
                    />
                    <p style={{ wordBreak: "break-all" }} >
                      <span className="text-primary">
                        {" "}
                        {ReplyMessage.length}{" "}
                      </span>{" "}
                      / 2000
                    </p>
                  </Grid>
                </Grid>
              </Grid>
            ) : SelectedData?.ticket_update_status == "open" ? (
              <Grid
                container
                lg={12}
                display={"flex"}
                alignItems={"flex-start"}
                justifyContent={"flex-start"}
                className="mt-3"
              >
                <h6 className="font-weight-bold mx-2">Acceptance Message</h6>
                <Grid
                  container
                  lg={12}
                  xs={12}
                  className=" p-2 "
                  alignItems="center"
                  justifyContent="flex-end"
                >
                  <p style={{ wordBreak: "break-all" }} className="border p-2 rounded w-100">
                    {SelectedData.ticket_response
                      ? SelectedData.ticket_response
                      : "----"}
                  </p>
                </Grid>
                <h6 className="font-weight-bold mt-4 mx-2 ">Closing Message</h6>
                <Grid container lg={12} xs={12} className="p-2">
                  <Grid
                    container
                    lg={12}
                    xs={12}
                    className="input_bg"
                    alignItems="center"
                    justifyContent="flex-end"
                  >
                    <textarea
                      className="Textarea  textAreaHeight"
                      placeholder="Enter Message here"
                      value={CloseMessage}
                      onChange={(e) => setCloseMessage(e.target.value)}
                      maxLength={2000}
                      rows={6}
                    />
                    <Grid
                      container
                      display={"flex"}
                      flexDirection={"row"}
                      alignItems={"center"}
                      justifyContent={"space-between"}
                    >
                      {/* */}
                      <Grid>
                        {/* eslint-disable */}
                        <label className="m-0 pointer" htmlFor="upload-photos" >
                          <input
                            id="upload-photos"
                            style={{ display: "none" }}
                            type="file"
                            multiple={true}
                            maxLength={5}
                            accept="image/*"
                            onChange={(e) => {
                              uploadFile(e.target.files, "reply");
                            }}
                          />  {FileLoader ? <CircularProgress size={20} style={{ color: "#000" }} /> : <IoMdAttach />} Attachment
                        </label>
                        {closeAttachments &&
                          closeAttachments?.map((file, i) => (
                            <p style={{ display: "flex", flexDirection: 'row' }} > <p onClick={() => Preview(file.file)}> {file.filename} </p>  <AiFillCloseCircle onClick={() => { DeleteCloseFile(i) }} /> </p>
                          ))

                        }
                        {/* eslint-enable */}
                      </Grid>
                      <p style={{ wordBreak: "break-all" }} >
                        <span className="text-primary">
                          {" "}
                          {CloseMessage.length}{" "}
                        </span>{" "}
                        / 2000
                      </p>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            ) : SelectedData?.ticket_update_status == "closed" ? (
              <Grid
                container
                lg={12}
                display={"flex"}
                alignItems={"flex-start"}
                justifyContent={"flex-start"}
                className="mt-3"
              >
                <h6 className="font-weight-bold mx-2">Closed Message</h6>
                <Grid
                  container
                  lg={12}
                  xs={12}
                  className=" p-2 "
                  alignItems="center"
                  justifyContent="flex-end"
                >
                  <p style={{ wordBreak: "break-all" }} className="border p-2 rounded w-100">
                    {SelectedData.closing_message
                      ? SelectedData.closing_message
                      : "----"}
                  </p>
                </Grid>
              </Grid>
            ) : (
              <Grid
                container
                lg={12}
                display={"flex"}
                alignItems={"flex-start"}
                justifyContent={"flex-start"}
                className="mt-3"
              >
                <h6 className="font-weight-bold mx-2">Reason for Rejection</h6>
                <Grid
                  container
                  lg={12}
                  xs={12}
                  className=" p-2"
                  alignItems="flex-start"
                  justifyContent="flex-start"
                >
                  <p style={{ wordBreak: "break-all" }} className="border p-2 rounded ">
                    {SelectedData.ticket_reject_response}
                  </p>
                </Grid>
              </Grid>
            )}

            {SelectedData?.closing_attachment && (
              <>
                <h6 className="font-weight-bold mt-3 mx-2">
                  {" "}
                  Closing Attachments from Admin{" "}
                </h6>
                <Grid
                  container
                  lg={12}
                  display={"flex"}
                  flexDirection={"row"}
                  alignItems={"strecth"}
                  justifyContent={"flex-start"}
                  className="p-2"
                >
                  {SelectedData?.closing_attachment?.values.map((row, i) => (
                    <Grid
                      item
                      key={i}
                      className="p-1 mt-2"
                      sx={{ cursor: "pointer" }}
                      onClick={() => Preview(row.file)}
                    >
                      <img
                        className="rounded mx-auto"
                        src={GetPublicURL(row.file)}
                        alt="Attachments"
                        style={{ height: "100px", width: "100px" }}
                      />
                    </Grid>
                  ))}
                </Grid>
              </>
            )}

            {SelectedData?.ticket_update_status == "pending" ? (
              <Grid
                container
                lg={12}
                display={"flex"}
                flexDirection={"row"}
                alignItems={"center"}
                justifyContent={"flex-end"}
                className="mt-5"
              >
                <AuthBtn
                  text={"Reject"}
                  btnColor={"#F84F31"}
                  btnTextColor="#ffff"
                  onClick={() => setRejectedModal(true)}
                />
                <div className="p-2" />
                <AuthBtn
                  text={"Forward to Maintenance"}
                  btnColor={"#0183FD"}
                  btnTextColor="#ffff"
                  onClick={() => setMaintenanceModal(true)}
                />
                <div className="p-2" />
                <AuthBtn
                  text={"Approve"}
                  btnColor={"#23C552"}
                  btnTextColor="#ffff"
                  onClick={() => ValidApprove()}
                />
              </Grid>
            ) : SelectedData?.ticket_update_status == "open" ? (
              <Grid
                container
                lg={12}
                display={"flex"}
                flexDirection={"row"}
                alignItems={"center"}
                justifyContent={"center"}
                className="mt-5"
              >
                <AuthBtn
                  text={"Close Ticket"}
                  btnColor={"#666666"}
                  btnTextColor="#ffff"
                  onClick={() => CloseTicketModal()}
                />
              </Grid>
            ) : (
              <Grid
                container
                lg={12}
                display={"flex"}
                flexDirection={"row"}
                alignItems={"center"}
                justifyContent={"center"}
                className="mt-5"
              >
                <AuthBtn
                  text={"Back"}
                  btnColor={"#666666"}
                  btnTextColor="#ffff"
                  onClick={() => setViewTicketModal(false)}
                />
              </Grid>
            )}

            <div className="p-3" />
          </Grid>
        }
      />

      <ImagePreviewModal
        open={ViewImage}
        setOpen={setViewImage}
        Image={SelectedImage}
      />

      <ConfirmationModal
        open={RejectedModal}
        setOpen={setRejectedModal}
        width={"45%"}
        modalTitle={<h5 className="font-weight-bold">Reject Ticket</h5>}
        modalContent={
          <Grid
            container
            lg={12}
            display={"flex"}
            flexDirection={"column"}
            alignItems={"flex-start"}
          >
            <p className="text-muted">
              Are you sure you want to reject this Ticket? rejecting this Ticket
              will change the status to rejected permanently.
            </p>
            <p className="font-weight-bold mt-3">Reason for Rejection</p>
            <Grid
              container
              lg={12}
              xs={12}
              className="input_bg mt-2"
              alignItems="center"
              justifyContent="flex-end"
            >
              <textarea
                className="Textarea  textAreaHeight"
                placeholder="Enter Message here"
                value={Reason}
                onChange={(e) => setReason(e.target.value)}
                maxLength={2000}
                rows={6}
              />
              <p>
                <span className="text-primary"> {Reason.length} </span> / 2000
              </p>
            </Grid>
            <hr className="w-100" />

            <Grid
              container
              lg={12}
              display={"flex"}
              flexDirection={"row"}
              alignItems={"center"}
              justifyContent={"flex-end"}
              className="mt-4"
            >
              <AuthBtn
                text={"Cancel"}
                btnColor={"#FFFF"}
                btnTextColor="#000"
                onClick={() => setRejectedModal(false)}
              />
              <div className="p-2" />
              <AuthBtn
                text={"Reject"}
                btnColor={"#F84F31"}
                btnTextColor="#fff"
                disabled={RejectLoader}
                onClick={() => RejectValidation()}
              />
            </Grid>
          </Grid>
        }
      />
      <ConfirmationModal
        open={MaintenanceModal}
        setOpen={setMaintenanceModal}
        width={"45%"}
        modalTitle={<h5 className="font-weight-bold">Forward to maintenance team</h5>}
        modalContent={
          <Grid
            container
            lg={12}
            display={"flex"}
            flexDirection={"column"}
            alignItems={"flex-start"}
          >
            <p className="text-muted">
              Are you sure you want to Forward this Ticket to maintenance team ?
            </p>
            <Grid
              container
              lg={12}
              display={"flex"}
              flexDirection={"row"}
              alignItems={"center"}
              justifyContent={"flex-end"}
              className="mt-4"
            >
              <AuthBtn
                text={"Cancel"}
                btnColor={"#FFFF"}
                btnTextColor="#000"
                onClick={() => setRejectedModal(false)}
              />
              <div className="p-2" />
              <AuthBtn
                text={"Forward"}
                btnColor={"#0183FD"}
                btnTextColor="#fff"
                // disabled={RejectLoader}
                onClick={() => ForwardConfirmed()}
              />
            </Grid>
          </Grid>
        }
      />

      <ConfirmationModal
        open={ApproveModal}
        setOpen={setApproveModal}
        modalTitle={<h5 className="font-weight-bold"> Approve Ticket </h5>}
        modalContent={
          <Grid container>
            <p>Are you sure want to approve this Ticket ?</p>
            <Grid
              container
              lg={12}
              display={"flex"}
              flexDirection={"row"}
              alignItems={"center"}
              justifyContent={"flex-end"}
              className="mt-4"
            >
              <AuthBtn
                text={"Cancel"}
                btnColor={"#ffff"}
                btnTextColor="#000"
                onClick={() => setApproveModal(false)}
              />
              <div className="p-2" />
              <AuthBtn
                text={"Approve"}
                btnColor={"#23C552"}
                btnTextColor="#ffff"
                disabled={ApproveLoader}
                onClick={() => ApproveConfirmed()}
              />
            </Grid>
          </Grid>
        }
      />
      <ConfirmationModal
        open={ClosingModal}
        setOpen={setClosingModal}
        modalTitle={<h5 className="font-weight-bold"> Close Ticket </h5>}
        modalContent={
          <Grid container>
            <p>Are you sure want to close this Ticket ?</p>
            <Grid
              container
              lg={12}
              display={"flex"}
              flexDirection={"row"}
              alignItems={"center"}
              justifyContent={"flex-end"}
              className="mt-4"
            >
              <AuthBtn
                text={"Cancel"}
                btnColor={"#ffff"}
                btnTextColor="#000"
                onClick={() => setClosingModal(false)}
              />
              <div className="p-2" />
              <AuthBtn
                text={"Close"}
                btnColor={"#23C552"}
                btnTextColor="#ffff"
                onClick={() => CloseConfirmed()}
              />
            </Grid>
          </Grid>
        }
      />
    </Grid>
  );
};

export default TicketsHomeTable;
