import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./Redux/store";
import "./index.css";
import "./Assets/fonts/InterFonts/InterBold.ttf";
import "./Assets/fonts/InterFonts/InterBlack.ttf";
import "./Assets/fonts/InterFonts/InterExtraBold.ttf";
import "./Assets/fonts/InterFonts/InterExtraLight.ttf";
import "./Assets/fonts/InterFonts/InterLight.ttf";
import "./Assets/fonts/InterFonts/InterMedium.ttf";
import "./Assets/fonts/InterFonts/InterRegular.ttf";
import "./Assets/fonts/InterFonts/InterSemiBold.ttf";
import "./Assets/fonts/InterFonts/InterThin.ttf";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>

  </Provider>
)
serviceWorker.unregister();
