import React, { useState } from "react";
import moment from "moment";
import {
  DateRangePicker,
  defaultStaticRanges,
  createStaticRanges,
} from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import AuthBtn from "../Button/AuthBtn";
import { Grid } from "@mui/material";
import DateShow from "../Modal/DateShowModal";
import { FormatDateOnly } from "./DateFromat";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { useEffect } from "react";
import { FaCalendarAlt } from "react-icons/fa";
function DatePicker({ OnDateApply, timeout }) {
  const [isShow, setIsShow] = useState(false);

  useEffect(() => {
    if (timeout) {
      setdefalutvalue();
    }
  }, [timeout]);

  const [ranges, setRanges] = useState([
    {
      startDate: null,
      endDate: null,
      key: "rollup",
    },
  ]);

  const setdefalutvalue = () => {
    setRanges([
      {
        startDate: null,
        endDate: null,
        key: "rollup",
      },
    ]);
  };

  const OnSubmit = () => {
    setIsShow(!isShow);
    OnDateApply(ranges[0]);
  };
  const staticRanges = createStaticRanges([
    ...defaultStaticRanges,
    {
      label: "This Year",
      range: () => ({
        startDate: moment().startOf("year").toDate(),
        endDate: moment().endOf("day").toDate(),
      }),
    },
    {
      label: "Last Year",
      range: () => ({
        startDate: moment().subtract(1, "years").startOf("year").toDate(),
        endDate: moment().subtract(1, "years").endOf("year").toDate(),
      }),
    },
  ]);

  const onCanel = () => {
    setRanges([
      {
        startDate: null,
        endDate: null,
        key: "rollup",
      },
    ]);
    setIsShow(!isShow);
  };

  return (
    <Grid container lg={12} className={"w-100"}>
      <DateShow
        open={isShow}
        setOpen={setIsShow}
        modalContent={
          <Grid
            container
            alignItems={"center"}
            justifyContent={"center"}
            className="m-2"
          >
            <DateRangePicker
              startDatePlaceholder="Start Date"
              endDatePlaceholder="End Date"
              ranges={ranges}
              maxDate={new Date()}
              onChange={(ranges) => setRanges([ranges.rollup])}
              staticRanges={staticRanges}
              inputRanges={[]}
            />
            <div className="p-3" />
            <Grid
              container
              lg={12}
              xs={12}
              justifyContent={"center"}
              alignItems={"center"}
              calssName="mt-5"
            >
              <AuthBtn
                className="close-btn mx-2"
                btnColor={"red"}
                btnTextColor="#fff"
                text={"Cancel"}
                onClick={() => onCanel()}
              />
              <AuthBtn
                className="close-btn mx-2"
                btnColor={"#23C552"}
                btnTextColor="#fff"
                text={"Submit"}
                onClick={() => OnSubmit()}
              />
            </Grid>
          </Grid>
        }
      />
      {ranges[0].endDate === null ? (
        <Grid container lg={12} xs={12} className="w-100">
          <AuthBtn
            text={"Filter By Date"}
            btnColor={"#ffff"}
            btnTextColor="#000"
            borderColor={"#e8e8e8"}
            onClick={() => setIsShow(!isShow)}
            Icon={<FaCalendarAlt />}
            style={{width:"100%"}}
          />
        </Grid>
      ) : (
        <Grid
          container
          lg={12}
          xs={12}
          className="border rounded p-2"
          flexDirection={"row"}
          justifyContent={"space-around"}
          onClick={() => setIsShow(!isShow)}
          sx={{ cursor: "pointer" }}
        >
          <CalendarMonthIcon
            fontSize="small"
            onClick={() => setIsShow(!isShow)}
            style={{ cursor: "pointer" }}
          />
          <p>{FormatDateOnly(ranges[0].startDate.getTime())}</p>
          <p className="mx-1">To</p>
          <p>{FormatDateOnly(ranges[0].endDate.getTime())}</p>
        </Grid>
      )}
    </Grid>
  );
}

export default DatePicker;
