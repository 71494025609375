import React from "react";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import { styled } from "@mui/system";
const CustomTextField = styled(TextField)`
  .icon {
    margin-right: ${({ theme }) => theme.spacing(1)};
  }
`;
const CustomInput = ({
  icon,
  placeholder,
  Value,
  handleInput,
  name,
  inputType,
  height,
  StartIcon,
  iconClick,
  className,
  disabled,
  readOnly,
  maxLength,
  Value1,
  TextColor,
  BgColor,
  placeHolder,
  iconColor,
  isBorder,
  borderColor,
}) => {
  const enforceMaxLength = (event) => {
    const input = event.target;
    const inputValue = input.value;

    // Apply maxLength validation
    if (maxLength && inputValue.length > maxLength) {
      input.value = inputValue.slice(0, maxLength);
    }
  };

  return (
    <CustomTextField
      fullWidth
      placeholder={placeholder}
      disabled={disabled}
      inputProps={{
        maxLength: maxLength ? maxLength : 40,
        readOnly: readOnly,
        inputMode: inputType === "number" ? "numeric" : "text",
      }}
      autoComplete="new-password"
      className={`${className}"customInput label"`}
      type={inputType ? inputType : "text"}
      sx={{
        "& .MuiOutlinedInput-root.Mui-focused": {
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#e8e8e8",
          },
        },
        "& .MuiOutlinedInput-root": {
          "& fieldset": {
            borderColor: borderColor ? borderColor : "",
          },
          "&:hover fieldset": {
            borderColor: "#e8e8e8",// Set the hover border color to transparent
          },
          height: height ? height : "50px",
          fontFamily: "InterRegular",
          fontSize: "16px",
          color: TextColor ? TextColor : "#000",
          backgroundColor: BgColor ? BgColor : "#ffff",
          borderRadius: isBorder ? "11px" : "",
        },
        borderRadius: "10px",
        borderColor: borderColor ? borderColor : "",
        "input::-internal-autofill-selected ": {
          backgroundColor: BgColor ? "#000" : "#000",
          "-webkit-box-shadow": "0 0 0 100px #000 inset",
          "-webkit-text-fill-color": BgColor ? BgColor : "#000",
        },
        "input::-webkit-inner-spin-button": {
          "-webkit-appearance": "none",
          margin: 0,
        },
        "& input::placeholder": {
          color: placeHolder ? placeHolder : "#333333",
        },
        "&:-webkit-autofill": {
          "-webkit-box-shadow": "0 0 0 100px #000 inset",
          "-webkit-text-fill-color": BgColor ? BgColor : "#fff",
          backgroundColor: BgColor ? BgColor : "#fff",
        },
        "input:-webkit-autofill ": {
          "background-color": "#000"
        }
      }}
      value={Value}
      defaultValue={Value1}
      onWheel={(e) => e.target.blur()}
      onChange={(e) => handleInput(e.target.value, name)}
      onInput={enforceMaxLength}

      InputProps={{
        inputProps: { min: 0 },
        endAdornment: icon ? (
          <InputAdornment position="end">
            <IconButton
              onClick={iconClick}
              // onMouseDown={iconClick}
              edge="end"
              sx={{ cursor: "pointer" }}
            >
              {icon && (
                <span
                  className="icon"
                  style={{ color: iconColor ? iconColor : "#000" }}
                >
                  {icon}
                </span>
              )}
            </IconButton>
          </InputAdornment>
        ) : (
          ""
        ),
        startAdornment: (
          <InputAdornment position="start">
            {StartIcon && (
              <span
                className="icon"
                style={{ color: iconColor ? iconColor : "#000" }}
              >
                {StartIcon}
              </span>
            )}{" "}
          </InputAdornment>
        ),
      }}
    />
  );
};

export default CustomInput;
