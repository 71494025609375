import React from "react";
import { Routes, Route } from "react-router-dom";
import TicketsHome from "../Pages/Tickets";
// import Clients from "../Pages/Clients";
import ErrorLogs from "../Pages/ErrorLogs"
import Jenkins from "../Pages/Jenkins"
import AWS from "../Pages/AWS"

const CommonRoutes = () => {
  return (
    <Routes>
      <Route extact path="/" element={<TicketsHome />} />
      <Route extact path="/logs" element={<ErrorLogs />} />
      <Route extact path="/jenkins" element={<Jenkins />} />
      <Route extact path="/aws" element={<AWS />} />
    </Routes>
  );
};
export default CommonRoutes;
