import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import { FaJenkins ,FaAws} from "react-icons/fa";
import { MdErrorOutline } from "react-icons/md";
import { Avatar, Grid } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import Badge from "@mui/material/Badge";
import { IoLogOut } from "react-icons/io5";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { useSelector } from "react-redux";

import CommonRoutes from "../../Routes/CommonRoutes";
import ConfirmationModal from "../../Components/Modal/Modal";
import AuthBtn from "../../Components/Button/AuthBtn";
import BarLogo from "../../Assets/images/Bar_logo.png";
// import UserProfile from "../../Assets/images/UserProfile.png";
import NotificationS from "./Notification";
import { GetPublicURL } from "../../Components/Helper/api";
const drawerWidth = 80;

const openedMixin = (theme) => ({
  width: drawerWidth,
  backgroundColor: " var(--light_Brown_color)",
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  backgroundColor: " var(--light_Brown_color)",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    padding: 0,
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function MiniDrawer() {
  const Wallet = useSelector((state) => state.WalletConnect);
  const { messagecount ,ticketcount } = Wallet;

  const location = useLocation();
  const navigate = useNavigate();
  const [sideBarData, setSideBarData] = useState([]);
  const [ChatCount, setChatCount] = useState(0);
  const [TicketsCount, setTicketsCount] = useState(0);
  const [Title, setTitle] = useState("Dashboard");
  const User = JSON.parse(localStorage.getItem("@users"))
  const Admin_name = User?.first_name + " " + User?.last_name

  useEffect(() => {
    setChatCount(messagecount)
  }, [messagecount]);
  useEffect(()=>{
    setTicketsCount(ticketcount)
  },[ticketcount])
  useEffect(() => {
    setSideBarData(SuperAdminData);

  }, []);

  
  const SuperAdminData = [
    {
      title: "Home",
      path: "/",
      icon: <img src={BarLogo} alt="logo" />,
      active: true,
      count: false,
    },
    {
      title: "Error logs",
      path: "/logs",
      icon: <MdErrorOutline size={"22px"} />,
      active: true,
      count: false,
    },
    {
      title: "Jenkins",
      path: "/jenkins",
      icon: <FaJenkins size={"22px"} />,
      active: true,
      count: false,
    },
    {
      title: "AWS",
      path: "/aws",
      icon: <FaAws size={"22px"} />,
      active: true,
      count: false,
    },
    {
      title: "Logout",
      icon: <IoLogOut size={"24px"} color="#fff" />,
      active: true,
      count: false,
    },
  ];

  const locationfileter = () => {
    if (location.pathname == "/") {
      return "/";
    } else if (
      location.pathname == "/clients" ||
      location.pathname == "/addclient" ||
      location.pathname == "/editclient" ||
      (location.pathname).includes("clientdetails")
    ) {
      return "/clients";
    } else if (location.pathname == "/inquiry") {
      return "/inquiry";
    } else if (location.pathname == "/chat" || location.pathname == "/PreviewChat" ) {
      return "/chat";
    } else if (
      location.pathname == "/billing" ||
      location.pathname == "/invoice"
    ) {
      return "/billing";
    } else if (location.pathname == "/tickets") {
      return "/tickets";
    } else if (
      location.pathname == "/settings/profile" ||
      location.pathname == "/settings/invoices" ||
      location.pathname == "/settings/tickets" ||
      location.pathname == "/settings/subscriptionplans" ||
      location.pathname == "/settings/termsandconditions" ||
      location.pathname == "/settings/privacypolicy" ||
      location.pathname == "/settings/faq"
    ) {
      return "/settings/profile";
    } else {
      return location.pathname;
    }
  };
  const DisplayingSize = () => {
    return 3;
  };
  const [openModal, setopenModal] = useState(false);
  const LogoutFunction = () => {
    setopenModal(false);
    localStorage.clear();
    navigate("/");
    window.location.reload("/");
  };

  const navigateFunction = (item) => {
    if (item.title == "Logout") {
      setopenModal(true);
      return;
    }
    setTitle(item.title);
    navigate(item.path);
  };

  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#454444",
      color: "var(--Light_Yellow_color)",
      boxShadow: theme.shadows[1],
      fontSize: "14px",
      fontFamily: "InterMedium",
      width: "100px",
      padding: "12.5px",
      textAlign: "center",
    },
  }));

  const BatchCustom = styled(Badge)(() => ({
    ".MuiBadge-badge": {
      fontFamily: "InterMedium",
      backgroundColor: "#D84B10",
      color: "#fff",
    },
  }));
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        open={true}
        sx={{
          backgroundColor: "#fff",
          color: "var(--light_Brown_color)",
          mb: 5,
        }}
      >
        <Toolbar>
          <Grid container lg={3}>
            <p>{Title}</p>
          </Grid>
          <Grid container justifyContent={"flex-end"} alignItems={"center"}>
            <Grid className="p-2 pr-5"></Grid>
            <Grid className="p-2 pr-5">
              <NotificationS />
            </Grid>
            <Grid sx={{ padding: "1%" }} />
            <Grid
              className="p-2"
              display={"flex"}
              flexDirection={"column"}
              alignItems={"flex-start"}
            >
              <p
                style={{ textTransform: "capitalize" }}
                className="font-weight-bold"
              >
                {Admin_name?.length > 25 ? Admin_name?.substring(0, 25) + "..." : Admin_name}
              </p>
              <p className="">{User?.email?.length > 35 ? User?.email?.substring(0, 35) + "..." : User?.email}</p>
            </Grid>
            <Grid className="p-2 pr-5">
              <Avatar src={GetPublicURL(User?.profile_image)} />
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <Grid item>
        <Drawer variant="permanent" open={open}>
          <Divider />
          <List className="pt-4" sx={{ fontFamily: "InterSemiBold" }}>
            {sideBarData.map((text) => (
              <ListItem key={text.title} disablePadding>
                <LightTooltip title={text.title} placement="right">
                  <ListItemButton
                    sx={{
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      color: text.path == locationfileter() ? "#000" : "#fff",
                    }}
                    onClick={() => {
                      navigateFunction(text);
                    }}
                  >
                    <Grid
                      item
                      display={"flex"}
                      sx={{
                        justifyContent: "center",
                        flexDirection: "column",
                        alignItems: "center",
                        height: "50px",
                        borderRadius: "10px",
                        backgroundColor:
                          text.path == locationfileter()
                            ? "var(--Light_Yellow_color)"
                            : "",
                      }}
                    >
                      {text.count ?
                        <BatchCustom badgeContent={text.title =="Chat"? ChatCount : TicketsCount} overlap="circular" >
                          <ListItemIcon
                            sx={{ justifyContent: "center", color: text.path == locationfileter() ? "var(--light_Brown_color)" : "#fff", }}  >
                            {text.icon}
                          </ListItemIcon>
                        </BatchCustom>
                        :
                        <ListItemIcon
                          sx={{ justifyContent: "center", color: text.path == locationfileter() ? "var(--light_Brown_color)" : "#fff", }}  >
                          {text.icon}
                        </ListItemIcon>
                      }
                    </Grid>
                    {/* <ListItemText  primary={text.title} sx={{ opacity: open ? 1 : 0 }} /> */}
                  </ListItemButton>
                </LightTooltip>
              </ListItem>
            ))}
          </List>
        </Drawer>
      </Grid>
      {/* p:3 removeid over all */}
      <Box
        component="main"
        sx={{
          paddingTop: { xl: "1%", lg: "1%", md: "10%", sm: "15%", xs: "55%" },
          flexGrow: 1,
          p: DisplayingSize(),
          backgroundColor: "var(--body-background-color)",
        }}
      >
        <DrawerHeader />
        <CommonRoutes />
      </Box>
      <ConfirmationModal
        open={openModal}
        setOpen={setopenModal}
        pagename={"logout"}
        modalTitle={<h3>Log out</h3>}
        modalContent={
          <Grid
            container
            flexDirection={"column"}
            alignItems={"flex-start"}
            justifyContent={"flex-start"}
          >
            <h6 className="text-dark">Are you sure want to logout ?</h6>
            <hr className="w-100" />
            <Grid
              container
              flexDirection={"column"}
              lg={12}
              xs={12}
              xl={12}
              md={12}
              alignItems={"center"}
              justifyContent={"center"}
              className="mt-2"
            >
              <Grid
                container
                flexDirection={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
                lg={12}
                md={12}
                xs={12}
                className="w-100"
              >
                <Grid lg={5.5} md={5.5} xs={6}>
                  <AuthBtn
                    btnColor={"#fff"}
                    btnTextColor="var(--light_Brown_color)"
                    text={"Cancel"}
                    borderColor={"var(--light_Brown_color)"}
                    className="p-2 w-100"
                    onClick={() => setopenModal(false)}
                  />
                </Grid>
                <Grid lg={5.5} md={5.5} xs={6}>
                  <AuthBtn
                    text={"Logout"}
                    btnColor="var(--Yellow_color)"
                    btnTextColor="#000"
                    className=" close-btn w-100  mx-2 "
                    onClick={() => LogoutFunction()}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        }
      />
    </Box>
  );
}
