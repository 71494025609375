import { Grid, Paper } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import OtpInput from "react-otp-input";
import { toast } from "react-toastify";
import { RiErrorWarningFill } from "react-icons/ri";

import { BackButton, YellowColorButton } from "../../Components/materialTableStyle/StyleTableCell";
import Main_Logo from "../../Assets/images/OHS-Buddy-Logo.svg";
import { postMethod } from "../../Components/Helper/api";

const ForgotVerifyOtp = () => {
  const navigate = useNavigate();
  const route = useLocation();
  const [UserEmail, setUserEmail] = useState("");
  const [Otp, setOtp] = useState("");
  const [MailTimer, setMailTimer] = useState(120);
  const [TimerShow, setMailTimerShow] = useState(true);
  const [Invalid, setInvalid] = useState(false);
  const [ResendLoader, setResendLoader] = useState(false);
  const [otpLoader, setotpLoader] = useState(false);

  useEffect(() => {
    if (route.state?.Email) {
      setUserEmail(route.state.Email);
    }else{
      navigate("/")
    }
  }, []);

  useEffect(() => {
    if (MailTimer < 0) {
      setMailTimerShow(false);
    } else {
      setMailTimerShow(true);
    }
    const intervalId = setInterval(() => {
      setMailTimer((prevSeconds) => prevSeconds - 1);
    }, 1000);
    return () => clearInterval(intervalId);
  }, [MailTimer]);

  const ValidateOtp = async() => {
    if(!UserEmail){
      toast.error("Please Try again");
      navigate("/")
      return;
    }
    if (Otp.length < 6) {
      toast.error("Please enter a valid OTP");
      setInvalid(true);
      return;
    }
    try{
      setotpLoader(true)
      let url = "superadmin/verifyforgotOTP";
      let method = "POST"
      let params = {
        Email:UserEmail.toLocaleLowerCase(),
        "OTP":Otp,
      };
      let authtoken = "";
      let response = await postMethod({ url, params, authtoken, method });
      console.log("response",response)
      if(response.status){
        toast.success("OTP Verified Success")
        setInvalid(false);
        setotpLoader(false)
        navigate("/resetpassword", { state: { Email: UserEmail,accessToken:response.tokens.accessToken} })
      }else{
        setotpLoader(false)
        setInvalid(false);
        if(response.message){
          toast.error(response.message)
        }else{
          toast.error("Something Went Wrong ")
        }
      }
    }catch(Error){
      setotpLoader(false)
      setInvalid(false);
      console.log("ValidateOtp",Error)
      toast.error("Something Went Wrong ")
    }
  };

  const ResendOtp = async() => {
    if(!UserEmail){
      toast.error("Please Try again");
      navigate("/")
      return;
    }
    try{
      setResendLoader(true)
      let url = "superadmin/ForgotPassword";
      let method = "POST"
      let params = {
        Email:UserEmail.toLocaleLowerCase(),
      };
      let authtoken = "";
      let response = await postMethod({ url, params, authtoken, method });
      if(response.status){
        setResendLoader(false)
        toast.success("OTP sent successfully");
        setOtp("");
        setMailTimer(120);
      }else{
        setResendLoader(false)
        toast.error("Try again");
      }
    }catch(Error){
      setResendLoader(false)
      console.log("ResendOtp",Error)
      toast.error("Try again");
    }
  };
  return (
    <Grid
      container
      lg={12}
      display={"flex"}
      flexDirection={"row"}
      justifyContent={"flex-start"}
      className="AuthHeight"
    >
      <Grid
        container
        lg={12}
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"flex-start"}
        className=" background_image"
      >
        <Grid
          container
          xl={6}
          lg={6}
          md={6}
          sm={6}
          xs={2}
          className="p-5"
          display={"flex"}
          flexDirection={"column"}
          alignItems={"flex-start"}
          justifyContent={"flex-start"}
        >
          <img src={Main_Logo} alt="main_logo" />
          <div>
            <h3 className="text-light mt-5" style={{ textAlign: "left" }}>
              <h3 style={{ color: "var(--Yellow_color)" }}>
                Workplace Safety{" "}
              </h3>
              Best Practice Platform
            </h3>
          </div>
        </Grid>
        <Grid
          item
          lg={6}
          md={6}
          sm={6}
          xs={12}
          alignItems="center"
          justifyContent="center"
          display={"flex"}
        >
          <Grid
            container
            xxl={6}
            xl={8}
            lg={10}
            display={"flex"}
            flexDierection="column"
            alignItems="flex-start"
            justifyContent="flex-start"
          >
            <Paper
              sx={{
                backgroundColor: "#000",
                opacity:0.8
              }}
              className="w-100 p-5"
            >
              <Grid item container alignItems="flex-start">
                <h4 className="bold text-light m-0 mt">Verify OTP</h4>
                <Grid item container alignItems="flex-start">
                  <p className="regular text-light pt-3 m-0 text-left">
                    {`Enter the 6 digit OTP sent to the your email  ${UserEmail}`}
                  </p>
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12} container>
                  <p className="regular text-light pt-3 m-0">OTP</p>
                  <Grid
                    container
                    lg={12}
                    className="w-100 mt-3"
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    <OtpInput
                      value={Otp}
                      onChange={setOtp}
                      numInputs={6}
                      inputType="number"
                      inputStyle={{
                        backgroundColor: "#000",
                        height: "60px",
                        width: "100%",
                        color: "#ffff",
                        paddingInline: true,
                        borderWidth: 1.5,
                        borderRadius: "5px",
                        boxShadow: "none",
                        borderColor: "#fff",
                        fontFamily: "InterMedium",
                      }}
                      containerStyle={{
                        width: "100%",
                      }}
                      shouldAutoFocus={true}
                      renderSeparator={<span className="p-2"></span>}
                      renderInput={(props) => <input {...props} />}
                      placeholder={"000000"}
                    />
                  </Grid>

                  <Grid
                    conatiner
                    className="w-100 mt-4"
                    display={"flex"}
                    alignItems={"flex-end"}
                    justifyContent={"flex-end"}
                  >
                    {TimerShow ? (
                      <p className="text-light">
                        {" "}
                        Resend OTP in : {MailTimer} sec
                      </p>
                    ) : (
                      <button
                        className="btn btn-0 "
                        disabled={ResendLoader}
                        onClick={() => ResendOtp()}
                      >
                        {" "}
                        <p className="text-light">Resend OTP</p>{" "}
                      </button>
                    )}
                  </Grid>

                  <Grid
                    display={"flex"}
                    flexDirection={"column"}
                    justifyContent={"flex-start"}
                    alignItems={"center"}
                  >
                    {Invalid && (
                      <p className="text-danger " style={{ fontSize: "12px" }}>
                        <RiErrorWarningFill /> Incorrect OTP. Try again or click
                        Resend code to reset it.
                      </p>
                    )}
                  </Grid>

                  <Grid
                    container
                    display={"flex"}
                    flexDirection="row"
                    justifyContent="center"
                    alignItems="center"
                    className=" mt-4"
                    sx={{ overflow: "hidden" }}
                  >
                    <BackButton
                      // type="submit"
                      className="bg-white"
                      onClick={() => navigate("/")}
                      style={{ width: "35%" }}
                    >
                      Back
                    </BackButton>
                    <div className="p-2" />
                    <YellowColorButton
                      type="submit"
                      className="resetpasswordBtn"
                      style={{ width: "35%" }}
                      disabled={otpLoader}
                      onClick={() => ValidateOtp()}
                    >
                      Verify
                    </YellowColorButton>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ForgotVerifyOtp;
