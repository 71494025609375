import React from "react";
import { Modal, Box, Grid } from "@mui/material";
import { AiOutlineClose } from "react-icons/ai";
const DateShow = ({ open, setOpen, modalContent }) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "40%",
    bgcolor: "#fff",
    border: false,
    boxShadow: 24,
    borderRadius: "0.5em",
    // m:5,
    outline: "none",
  };

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Grid
          container
          flexDirection={"column"}
          justifyContent={"center"}
          alignItems={"center"}
          lg={12}
          xs={12}
          md={12}
          className="GradiantButton rounded-top p-3"
        >
          <Grid
            container
            flexDirection={"row"}
            justifyContent={"space-between"}
          >
            <h4 className="text-dark"> Filter By Date Range</h4>
            <AiOutlineClose
              onClick={() => setOpen(false)}
              style={{ cursor: "pointer" }}
              size={20}
              color="#fff"
              className="mx-2 mt-2"
            />
          </Grid>
        </Grid>
        {modalContent}
        <Grid className="p-3"></Grid>
      </Box>
    </Modal>
  );
};

export default DateShow;
