import React  from "react";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#454444",
    color: "#ffff",
    boxShadow: theme.shadows[1],
    fontSize: "14px",
    fontFamily: "InterMedium",
    padding: "8px",
    textAlign: "center",
  },
}));
export default CustomTooltip;
