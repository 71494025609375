const initialState = { messagecount: 0,ticketcount:0,  FCMToken: ""}

const walletConnectReducer = (state = initialState, action) => {
  switch (action.type) {
  case "MESSAGE_COUNT":
    return {  ...state,  messagecount: action.payload.messagecount  };
  case "TICKET_COUNT":
    return {  ...state,  ticketcount: action.payload.ticketcount  };
  case "FCM_TOKEN":
    return { ...state, FCMToken: action.payload.FCMToken };
  default:
    return state;
  }
};

export default walletConnectReducer;
