import React from "react";
import { Route, Routes } from "react-router-dom";

import Login from "../Pages/Authentication/Login";
import VerifyOtp from "../Pages/Authentication/VerifyOtp";
import ResetPassword from "../Pages/Authentication/ResetPassword";
import ForgotPassword from "../Pages/Authentication/ForgotPasssword";
import ForgotVerifyOtp from "../Pages/Authentication/ForgotVerifyOTP";

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/forgotpassword" element={<ForgotPassword />} />
      <Route path="/verifyotp" element={<VerifyOtp />} />
      <Route path="/resetpassword" element={<ResetPassword />} />
      <Route path="/otpverify" element={<ForgotVerifyOtp />} />
    </Routes>
  );
};
export default AppRoutes;
