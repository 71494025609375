import React from "react";
import { Modal, Box, Grid } from "@mui/material";
import { AiOutlineClose } from "react-icons/ai";
const ImagePreviewModal = ({ open, setOpen, Image }) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    height: "100%",
    bgcolor: "rgba(0, 0, 0, 0.7)",
    border: false,
    boxShadow: 24,
    borderRadius: "0.5em",
  };

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style} className="">
        <Grid
          container
          flexDirection={"column"}
          justifyContent={"center"}
          alignItems={"center"}
          lg={12}
          xs={12}
          md={12}
          className="GradiantButton rounded-top "
        >
          <Grid
            container
            flexDirection={"row"}
            justifyContent={"flex-end"}
            alignItems={"center"}
            className="p-3  rounded-top"
          >
            <AiOutlineClose
              onClick={() => setOpen(false)}
              style={{ cursor: "pointer", color: "#ffff" }}
              size={20}
              color="#fff"
              className="mx-2 text-end"
            />
          </Grid>
        </Grid>
        <hr className="w-100 m-0 p-0" />
        <Grid
          container
          lg={12}
          flexDirection={"row"}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          className="mt-5"
        >
          <Grid
            container
            lg={10}
            flexDirection={"row"}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <img
              src={Image}
              alt="prviewImage"
              className="rounded mx-auto d-block img-fluid"
              style={{ objectFit: "contain",width:"600px",height:"600px" }}
            />
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default ImagePreviewModal;
