import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';


const data = [
    {
        name: 'Page A',
        time: 4000,
        value: 2400,
        amt: 2400,
    },
    {
        name: 'Page B',
        time: 3000,
        value: 1398,
        amt: 2210,
    },
    {
        name: 'Page C',
        time: 2000,
        value: 9800,
        amt: 2290,
    },
    {
        name: 'Page D',
        time: 2780,
        value: 3908,
        amt: 2000,
    },
    {
        name: 'Page E',
        time: 1890,
        value: 4800,
        amt: 2181,
    },
    {
        name: 'Page F',
        time: 2390,
        value: 3800,
        amt: 2500,
    },
    {
        name: 'Page G',
        time: 3490,
        value: 4300,
        amt: 2100,
    },
];

export default function Linechart({ ServiceCost }) {


    const CustomTooltip = ({ active, payload }) => {
        if (active && payload && payload.length) {
            return (
                <div className="custom-tooltip" style={{ backgroundColor: "#fff", padding: 15, borderRadius: "10px" }} >
                    {
                        payload.map((data) => (
                            <div  >
                                <p>{`${data.time}`}</p>
                                <p>{`${data.value}`}</p>
                            </div>
                        ))
                    }
                </div>
            );
        }

        return null;
    };


    return (

        // <ResponsiveContainer width={1000} height={400} style={{justifyContent:"center"}} >

        <LineChart
            width={1000}
            height={400}
            data={ServiceCost}
            margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
            }}
        >
           
            <CartesianGrid vertical={false} />
            <XAxis dataKey="name" />
            <YAxis axisLine={false} />
            <Tooltip />
            {/* <Tooltip content={<CustomTooltip />} cursor={{ fill: 'transparent' }} /> */}
            <Legend />
            <Line type="monotone" dataKey="value" stroke="#8884d8" activeDot={{ r: 8 }} />
            {/* <Line type="monotone" dataKey="time" stroke="#82ca9d" /> */}
        </LineChart>
        // </ResponsiveContainer>
    );
}
