// export const REACT_APP_BASE_URL1 = "http://localhost:4000/api/"
export const REACT_APP_BASE_URL1 = "https://ohsbuddy.click/api/"
export const REACT_APP_AWS_ACCESSKEY_ID="AKIAVYJJIMUEW2H5QYQM"
export const REACT_APP_AWS_SECRET_KEY="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"
export const REACT_APP_AWS_BUCKET_NAME = "ohsbuddy"
export const REACT_APP_AWS_DIR_NAME="OHSBuddy"
export const REACT_APP_REGION="ap-southeast-2"
export const Encryprkey = "$76ohsEncryptionKey"
export const REACT_APP_DOCUMENT_S3_URL= "https://ohsbuddy.s3.amazonaws.com"
export const REACT_APP_S3_URL="https://ohsbuddy.s3.ap-southeast-2.amazonaws.com/"
export const REACT_APP_Captch_Key="6LcYxikpAAAAALrsthRbclRva6aP59BasK2DP67z"
export const REACT_APP_WEBSOCKET_URL="wss://ohsbuddy.click/"