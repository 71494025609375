import { Grid, Paper } from "@mui/material";
import React, { useState } from "react";
import CryptoJS from "crypto-js";
import { AiOutlineMail, AiFillLock } from "react-icons/ai";
import { VisibilityOff, Visibility } from "@mui/icons-material";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";

import CustomInput from "../../Components/Input/CustomInput";
import Main_Logo from "../../Assets/images/OHS-Buddy-Logo.svg";
import AuthBtn from "../../Components/Button/AuthBtn";
import { postMethod } from "../../Components/Helper/api";
import { Encryprkey, REACT_APP_Captch_Key } from "../../Config"

const Login = () => {
  const navigate = useNavigate();
  const [Email, setEmail] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [Password, setPassword] = useState("");
  const [Loader, setLoader] = useState(false);
  const [CaptchaVerified, setCaptchaVerified] = useState(false);
  const HandleClickShowPassword = () => setShowPassword((show) => !show);

  const handleCaptchaVerify = (response) => {
    if (response) {
      setCaptchaVerified(true);
    }
  };

  const encryptData = (data) => {
    const encryptedData = CryptoJS.AES.encrypt(data, Encryprkey).toString();
    return encryptedData;
  };

  const validationemails = (email) => {
    /* eslint-disable */
    const regExp =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regExp.test(email);
  };

  const validatePassword = (password) => {
    var passwordValidation = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/;
    return passwordValidation.test(password);
  };
  const ValidateLogin = async () => {

    if (!validationemails(Email)) {
      toast.warn("Please Enter a valid email address");
      return;
    }
    if (Password.trim().length == 0) {
      toast.warn("Please enter a valid password");
      return;
    }
    if (!validatePassword(Password)) {
      toast.warn("Your password must be at least 8 characters");
      return;
    }
    if (!CaptchaVerified) {
      toast.warn("Captcha verification");
      return;
    }
    setLoader(true)
    try {
      let url = "superadmin/maintenancelogin";
      let method = "POST"
      let Encrypte = encryptData(Password)
      let params = {
        Email: Email.toLocaleLowerCase(),
        Password: Encrypte,
      };
      let authtoken = "";
      let response = await postMethod({ url, params, authtoken, method });
      console.log("response", response)
      if (response.status) {
        setLoader(false)
        toast.success("OTP Sended Successfully");
        navigate("/verifyotp", { state: { Email: Email, Password: Encrypte } });
      } else {
        setLoader(false)
        if (response.message) {
          toast.error(response.message)
        } else {
          toast.error("Something Went Wrong ")
        }
      }
    } catch (Error) {
      setLoader(false)
      toast.error("Something Went Wrong ")
      console.log("Something went wrong", Error)
    }

  };
  return (
    <Grid
      container
      lg={12}
      display={"flex"}
      flexDirection={"row"}
      justifyContent={"flex-start"}
      alignItems={"stretch"}
      className="AuthHeight"
    >
      <Grid
        container
        lg={12}
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"center"}
        className=" background_image"
      >
        <Grid
          conatiner
          xl={8}
          lg={8}
          md={8}
          sm={8}
          xs={12}
          display={"flex"}
          flexDirection="column" className="p-5" justifyContent={"center"} alignItems={"center"} >
          {" "}
          <img src={Main_Logo} alt="main_logo" className="" />
          <div>
            <h3 className="text-light mt-5" style={{ textAlign: "left" }}>
              <span style={{ color: "var(--Yellow_color)" , fontSize:"28px" }}> Workplace Safety{" "}  </span> Best Practice Platform
            </h3>
          </div>
          <Grid
            container
            xxl={6}
            xl={8}
            lg={10}
            display={"flex"}
            flexDierection="row"
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Paper
              style={{ backgroundColor: "#000", opacity: 0.8 }}
              className="p-5 rounded w-100"
            >
              <Grid
                container
                lg={12}
                display={"flex"}
                flexDirection="column"
                alignItems={"flex-start"}
                justifyContent={"flex-start"}
              >
                <h4 className="text-light">Sign in</h4>
                <p className="text-light mt-4">Email</p>
                <div className="p-1" />
                <CustomInput
                  StartIcon={<AiOutlineMail size={25} />}
                  Value={Email}
                  placeHolder={"#fff"}
                  placeholder={"Enter Email"}
                  TextColor={"#ffff"}
                  BgColor={"#000"}
                  className={" border border-white border-rounded"}
                  handleInput={(e) => setEmail(e)}
                  iconColor={"#ffff"}
                  isBorder={true}

                />
                <p className="text-light mt-3" htmlFor="Password">
                  Password
                </p>
                <div className="p-1" />
                <CustomInput
                  className={" border border-white border-rounded text-light"}
                  placeholder={"Enter Password"}
                  Value={Password}
                  maxLength={50}
                  placeHolder={"#fff"}
                  TextColor={"#ffff"}
                  BgColor={"#000"}
                  StartIcon={<AiFillLock size={25} />}
                  inputType={showPassword ? "text" : "password"}
                  icon={showPassword ? <VisibilityOff /> : <Visibility />}
                  iconClick={() => HandleClickShowPassword()}
                  iconColor={"#ffff"}
                  isBorder={true}
                  handleInput={(e) => {
                    setPassword(e);
                  }}
                />

                <Grid
                  container
                  className=" w-100  mt-3 pb-3"
                  flexDirection={"row"}
                  justifyContent={"flex-end"}
                >
                  <button
                    className="btn btn-0 border border-0"
                    onClick={() => navigate("/forgotpassword")}
                  >
                    <p className="text-danger "> Forgot Password ?</p>
                  </button>
                </Grid>
                <Grid
                  container
                  className=" w-100  mt-3 pb-3"
                  flexDirection={"row"}
                  justifyContent={"flex-start"}
                >
                  <ReCAPTCHA sitekey={REACT_APP_Captch_Key} onExpired={() => { console.log("expired Captcha"); setCaptchaVerified(false) }} onChange={handleCaptchaVerify} />
                </Grid>
                <Grid
                  item
                  lg={12}
                  className=" w-100  mt-4 "
                  flexDirection={"row"}
                >
                  <AuthBtn
                    onClick={() => ValidateLogin()}
                    text={"Sign in"}
                    disabled={Loader}
                    className={"w-100"}
                    btnTextColor="#000"
                    btnColor={"#FFF241"}
                  />
                </Grid>
              </Grid>
              <div className="p-3" />
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Login;
