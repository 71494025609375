import { Avatar, CircularProgress, Grid, } from "@mui/material";
import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import { CiSearch } from "react-icons/ci";
import { FaFileCsv } from "react-icons/fa";
import { IoReloadOutline } from "react-icons/io5";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import CryptoJS from "crypto-js";

import { RiskStyledTableCell, StyledTableRow, CustomTablePagination } from "../../../Components/materialTableStyle/StyleTableCell";
import CustomInput from "../../../Components/Input/CustomInput";
import DatePicker from "../../../Components/DateFormats/Datepicker";
import { FormatDateOnly, formatedDateAndTime } from "../../../Components/DateFormats/DateFromat"
import CustomDropDown from "../../../Components/DropDown/DropDown";
import AuthBtn from "../../../Components/Button/AuthBtn";

import { csvDownload } from "../../../Components/CSVDownload/DownloadCSV"
import { FormatDate } from "../../../Components/DateFormats/DateFromat"
import { Encryprkey } from "../../../Config/index"
import ConfirmationModal from "../../../Components/Modal/ConfirmationModal"
import { postMethod } from "../../../Components/Helper/api"

const LogsTable = ({ ClientsData, Typeof, GetClientData, GetExitRequestData }) => {
    const authtoken = localStorage.getItem("@accessToken")
    const Admin = JSON.parse(localStorage.getItem("@users"))

    const navigate = useNavigate();
    const [InitialData, setInitialData] = useState([]);
    const [FilterDataData, setFilterDataData] = useState([]);
    const [Loader, setLoader] = useState(false);
    const [fromDate, setFromDate] = useState();
    const [timeout, settimeout] = useState(false);
    const [toDate, setToDate] = useState();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [Selected, setSelected] = useState("");
    const [SearchQuery, setSearchQuery] = useState("");
    const [SelectedData, setSelectedData] = useState([]);
    const [Subscription, setSubscription] = useState("");
    const [subStatus, setSubstatus] = useState("");
    const [Comments, setComments] = useState("");

    const [DetailsModal, setDetailsModal] = useState(false)

    useEffect(() => {
        setInitialData(ClientsData);
        setSelectedData(ClientsData);
        setFilterDataData(ClientsData)
        setLoader(true);
    }, [ClientsData]);

    useEffect(() => {
        if (Loader) {
            setTimeout(() => {
                setLoader(false);
            }, 1000);
        }
    }, [Loader]);

    //Date picker
    const handleDateApply = (picker) => {
        settimeout(false);
        setFromDate(picker.startDate.toString());
        setToDate(picker.endDate.toString());
        const StartDate = new Date(picker.startDate).getTime();
        const EndDate = new Date(picker.endDate).getTime();
        const enddate = EndDate + 86400000;
        handleDateChange(StartDate, enddate);
    };

    const handleDateChange = (fromDate, toDate) => {
        if (fromDate != undefined) {
            const displayedData_info = FilterDataData.filter((item) => Number(item.created_at) >= fromDate && Number(item.created_at) <= toDate)
            setInitialData(displayedData_info);
        }
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const HandleSearch = (e) => {
        const Datas = [...ClientsData];
        setSearchQuery(e);
        if (e.trim() == 0) {
            setInitialData(Datas)
            return
        }

        let filetedsearch = Datas.filter(
            (a) =>
                a.function_name?.toLowerCase().includes(e?.toLowerCase()) ||
                a.errors?.toLowerCase().includes(e?.toLowerCase())
        );
        setInitialData(filetedsearch);
        setFilterDataData(filetedsearch);
    };

    const RefreshData = () => {
        GetExitRequestData()
        GetClientData()
        setLoader(true);
        setSearchQuery("");
        setSelected("");
        setSubstatus("");
        setSubscription("");
        setFromDate("");
        setToDate("");
        settimeout(true);
        setInitialData(ClientsData);
    };
    const GetClientDatas = (Datas) => {
        console.log("Datas", Datas)
        navigate(`/clientdetails/${Datas.tenants_id}`, { state: Datas });
    };

    const HandleSideModal = (data) => {
        setSelectedData(data);
        setDetailsModal(true);
    };

    function capitalizeFirstLetter(str) {
        if (str && typeof str === "string") {
            return str.charAt(0).toUpperCase() + str.slice(1);
        } else {
            return str || "";
        }
    }

    const getNextPaymetDate = (data) => {
        if (data?.last_payment_date) {
            let date = data?.last_payment_date
            if (data?.payment_type == "Monthly") {
                const DaysInMilliseconds = 30 * 24 * 60 * 60 * 1000;
                let nextdate = Number(date) + DaysInMilliseconds
                nextdate = FormatDateOnly(nextdate)
                return nextdate ? nextdate : "---"
            } else {
                const DaysInMilliseconds = 365 * 24 * 60 * 60 * 1000;
                let nextdate = Number(date) + DaysInMilliseconds
                nextdate = FormatDateOnly(nextdate)
                return nextdate ? nextdate : "---"
            }
        }
    }
    const DownloadCSV = () => {
        let arr = []
        if (Typeof === "Clients") {
            FilterDataData.map((item, i) => {
                let status = item.trail_status ? " Trial " : item.payment_status ? "Active" : "Inactive"
                arr.push([
                    i + 1,
                    item?.company_name ? item.company_name : "---",
                    item?.register_address ? Decryption(item.register_address) : "---",
                    item?.tenants_email,
                    "+61 " + item?.tenants_contact,
                    item.plan_type ? capitalizeFirstLetter(item.plan_type) + "-" + status : "---",
                    getNextPaymetDate(item) ? getNextPaymetDate(item) : "---",
                    item.payment_type ? item.payment_type : "---",
                    item.tenants_status ? "Active" : "Inactive"])
            })
            const csvheaderData = [
                ["Report Name", "Clients"],
                ["Report Generated On: ", FormatDate(Date.now())],
                ["Sno", "Client Name", "Client Address", "Contact info", "Contact Number", "Subscription Plan / Status", "Payment Due", "Billing Frequency", "Account Status"],
                ...arr
            ]
            csvDownload(csvheaderData, "Client");
        } else {
            InitialData.map((item, i) => {
                let plan_status = item.trail_status ? " Trial " : item.payment_status ? "Active" : "Inactive"
                arr.push([
                    i + 1,
                    item?.company_name ? item.company_name : "---",
                    item?.register_address ? item.register_address : "---",
                    item?.tenants_email,
                    "+61 " + item?.tenants_contact,
                    item.plan_type ? item.plan_type + "-" + plan_status : "--",
                    // + item.trail_status ? " Trial " : item.payment_status ? "Active" : "Inactive",
                    FormatDateOnly(item.request_created_at),
                    item.tenants_status ? "Active" : "Inactive"])
            })
            const csvheaderData = [
                ["Report Name", "Clients Exit Requests "],
                ["Report Generated On: ", FormatDate(Date.now())],
                ["Sno", "Client Name", "Client Address", "Contact info", "Contact Number", "Subscription Plan / Status", "Exit Request Date", "Account Status"],
                ...arr
            ]
            csvDownload(csvheaderData, "Clients Exit Requests");
        }

        // if(Typeof === "Clients"){
        //   url = "reports/ClientPDF";
        // }else{
        //   url = "reports/ExitRequestPDF"
        // }

    }
    const Decryption = (encryptedData) => {
        if (encryptedData) {
            const decryptedBytes = CryptoJS.AES.decrypt(encryptedData, Encryprkey);
            const decryptedData = decryptedBytes?.toString(CryptoJS.enc.Utf8);
            return decryptedData;
        }

    };
    const CloseErrorDetails = async () => {
        if (SelectedData?.logs_id) {
            console.log("SelectedData", SelectedData)
            if (Comments.trim().length == 0) {
                toast.warn("Enter comments minimum length 15 characters")
                return
            }
            if (Comments.trim().length < 15) {
                toast.warn("Enter comments minimum length 15 characters")
                return
            }
            try {
                let url = "log/UpdateErrorlog";
                let method = "post"
                let params = {
                    "log_id": SelectedData?.logs_id,
                    comments: Comments
                }
                let response = await postMethod({ url, authtoken, method, params });
                if (response.status) {
                    toast.success("Error log Updated successfully")
                    GetExitRequestData()
                    setDetailsModal(false)
                } else {
                    toast.warn("Try Again later")
                }
            } catch (Error) {
                console.log("Error", Error)
                toast.warn("Try Again later ")
            }
        } else {
            setDetailsModal(false)
            toast.warn("Try Again later ")
        }
    }
    return (
        <Grid
            container
            lg={12}
            display={"flex"}
            flexDierection={"column"}
            alignItems={"flex-start"}
            justifyContent={"flex-start"}
        >
            <Grid
                container
                lg={12}
                display={"flex"}
                flexDirection={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
                className="mt-4"
            >
                <Grid
                    lg={3}
                    display={"flex"}
                    flexDirection={"row"}
                    alignItems={"center"}
                >
                    <h5 className="font-weight-bold">
                        Error Logs
                    </h5>
                </Grid>
                <Grid
                    container
                    lg={9}
                    display={"flex"}
                    flexDierection={"row"}
                    alignItems={"center"}
                    justifyContent={"flex-end"}
                >
                    <Grid item lg={3} className="p-1">
                        <CustomInput
                            placeholder={"Search.."}
                            Value={SearchQuery}
                            StartIcon={<CiSearch size={"20px"} />}
                            height={"40px"}
                            handleInput={(e) => HandleSearch(e)}
                        />
                    </Grid>
                    <Grid
                        item
                        className=" p-1"
                        display={"flex"}
                        alignItems={"flex-end"}
                        justifyContent={"flex-end"}
                        lg={2}
                    >
                        <DatePicker
                            setFromDate={fromDate}
                            setToDate={toDate}
                            OnDateApply={handleDateApply}
                            timeout={timeout}
                        />
                    </Grid>
                    <Grid
                        item
                        lg={1}
                        display={"flex"}
                        flexDirection={"row"}
                        alignItems={"center"}
                        justifyContent={"flex-end"}
                    >
                        {/* <div className="p-1" /> */}
                        {/* <button onClick={() => DownloadCSV()} className="btn btn-light  border rounded">
                            <FaFileCsv size={"22px"} color="#23C552" />
                        </button> */}
                        {/* <div className="p-1" /> */}
                        <button
                            className="btn btn-light  border rounded"
                            onClick={() => RefreshData()}
                        >
                            <p>
                                <IoReloadOutline
                                    size={"22px"}
                                    style={{ fontWeight: "bolder" }}
                                    color="#0183FD"
                                />{" "}
                            </p>
                        </button>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container lg={12} className="mt-4">
                <TableContainer>
                    <Table
                        //   aria-label="customized table"
                        stickyHeader
                        aria-label="sticky table"
                    >
                        <TableHead className="rounded">
                            <StyledTableRow>
                                <RiskStyledTableCell>Function  Name</RiskStyledTableCell>
                                <RiskStyledTableCell align="left">
                                    Errors{" "}
                                </RiskStyledTableCell>
                                <RiskStyledTableCell align="left">
                                    Created Time{" "}
                                </RiskStyledTableCell>
                                
                                <RiskStyledTableCell>
                                    Updated on
                                </RiskStyledTableCell>
                                <RiskStyledTableCell>
                                    Status
                                </RiskStyledTableCell>
                                <RiskStyledTableCell align="center" >
                                    Action
                                </RiskStyledTableCell>
                            </StyledTableRow>
                        </TableHead>
                        {!Loader ? (
                            InitialData?.length > 0 ? (
                                <TableBody>
                                    {InitialData?.slice(
                                        page * rowsPerPage,
                                        page * rowsPerPage + rowsPerPage
                                    ).map((row, i) => (
                                        <StyledTableRow key={i}>
                                            <RiskStyledTableCell
                                                align="left"
                                                onClick={() =>
                                                    Typeof === "Clients" ? GetClientDatas(row) : ""
                                                }
                                                sx={{ cursor: "pointer" }}
                                            >
                                                <Grid
                                                    container
                                                    display={"flex"}
                                                    flexDierection={"row"}
                                                    alignItems={"center"}
                                                    justifyContent={"flex-start"}
                                                >
                                                    {/* <Avatar src={row.business_logo} /> */}
                                                    <Grid className="mx-2">
                                                        <p> {row?.function_name} </p>
                                                    </Grid>
                                                </Grid>
                                            </RiskStyledTableCell>
                                            <RiskStyledTableCell>
                                                <p style={{ cursor: "pointer" }}>
                                                    {row.errors}
                                                </p>
                                            </RiskStyledTableCell>
                                            <RiskStyledTableCell>
                                                <p style={{ cursor: "pointer" }}>
                                                    {formatedDateAndTime(row.created_at)}
                                                </p>
                                            </RiskStyledTableCell>
                                            <RiskStyledTableCell>
                                                <p style={{ cursor: "pointer", textTransform: "capitalize" }}>
                                                    {row.updated_on? formatedDateAndTime(row.updated_on):"---" }
                                                </p>
                                            </RiskStyledTableCell>
                                            <RiskStyledTableCell>
                                                <p style={{ cursor: "pointer", textTransform: "capitalize",color: row.update_status == "pending" ? "red":"green" }}>
                                                    {row.update_status}
                                                </p>
                                            </RiskStyledTableCell>
                                            <RiskStyledTableCell>
                                                <AuthBtn
                                                    text={"View"}
                                                    btnColor={"#4EAD5B"}
                                                    btnTextColor="#fff"
                                                    onClick={() => HandleSideModal(row, i)}
                                                />
                                            </RiskStyledTableCell>

                                        </StyledTableRow>
                                    ))}
                                </TableBody>
                            ) : (
                                <TableBody>
                                    <StyledTableRow>
                                        <RiskStyledTableCell colSpan={6} align="center">
                                            <p className="text-center">No Clients Available</p>
                                        </RiskStyledTableCell>
                                    </StyledTableRow>
                                </TableBody>
                            )
                        ) : (
                            <TableBody>
                                <StyledTableRow>
                                    <RiskStyledTableCell colSpan={6} align="center">
                                        <span className="regular">
                                            <CircularProgress style={{ color: "#000" }} />
                                        </span>
                                    </RiskStyledTableCell>
                                </StyledTableRow>
                            </TableBody>
                        )}
                    </Table>

                    {InitialData?.length > 10 && (
                        <Grid
                            container
                            lg={12}
                            xl={12}
                            xs={12}
                            justifyContent={"flex-end"}
                            alignItems={"flex-end"}
                            style={{ backgroundColor: "#FAFAFA" }}
                        >
                            <CustomTablePagination
                                className="paginationintable w-100"
                                component="div"
                                rowsPerPageOptions={[10, 25, 50, { value: -1, label: "All" }]}
                                count={InitialData.length}
                                page={page}
                                rowsPerPage={rowsPerPage}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                labelRowsPerPage=""
                            />
                        </Grid>
                    )}
                </TableContainer>
            </Grid>
            <ConfirmationModal
                open={DetailsModal}
                setOpen={setDetailsModal}
                width={"45%"}
                modalTitle={<h5 className="font-weight-bold">Update Error Details</h5>}
                modalContent={
                    <Grid container lg={12} style={{ padding: 10, width: "100%" }} >
                        <Grid container lg={12} style={{ padding: 10, justifyContent: "space-around" }}>
                            <Grid lg={6} style={{ flexDirection: "column", display: "flex" }} >
                                <h5> Function Name</h5>
                                <p> {SelectedData?.function_name}</p>
                            </Grid>
                            <Grid lg={6} style={{ flexDirection: "column", display: "flex" }}>
                                <h5> Created Time</h5>
                                <p  >  {SelectedData?.created_at && formatedDateAndTime(SelectedData?.created_at)}</p>
                            </Grid>

                        </Grid>
                        <Grid container lg={12} style={{ padding: 10, justifyContent: "space-around" }}>
                            <Grid container lg={12} style={{ flexDirection: "column", display: "flex", marginTop: 10 }}>
                                <h5> Error Details</h5>
                                <p style={{ color: "red" }} > {SelectedData?.errors}</p>
                            </Grid>
                        </Grid>
                        <Grid container lg={12} style={{ padding: 10 }} >
                            <p>Comments</p>


                            {SelectedData.update_status == "pending" ?
                                <Grid container lg={12}
                                    xs={12}
                                    className="input_bg mt-2"
                                    alignItems="center"
                                    justifyContent="flex-end"
                                >
                                    <textarea className="Textarea  textAreaHeight" placeholder="Enter your comments..." value={Comments}
                                        maxLength={2000} rows={9} onChange={(e) => setComments(e.target.value)} style={{ backgroundColor: "#F2F2F2", padding: 5 }}
                                    />
                                     <p>{Comments?.trim().length }/ 2000</p>
                                </Grid>
                                :
                                <p>{SelectedData.log_comment}</p>
                            }
                        </Grid>
                        <Grid
                            container
                            lg={12}
                            display={"flex"}
                            flexDirection={"row"}
                            alignItems={"center"}
                            justifyContent={"flex-end"}
                            className="mt-4"
                        >
                            <AuthBtn
                                text={"close"}
                                btnColor={"#FFFF"}
                                btnTextColor="#000"
                                onClick={() => setDetailsModal(false)}
                            />
                            <div className="p-2" />
                            {SelectedData.update_status == "pending" &&
                                <AuthBtn
                                    text={"Update"}
                                    btnColor={"#F84F31"}
                                    btnTextColor="#fff"
                                    // disabled={DeActivateLoader}
                                    onClick={() => CloseErrorDetails()}
                                />
                            }
                        </Grid>
                    </Grid>
                }
            />
        </Grid>
    );
};

export default LogsTable;
