import React, { useEffect } from "react";
import { Grid, Modal, Box} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import {  GreyColorButton } from "../materialTableStyle/StyleTableCell";
const NetworkModal = ({ open, handleClose }) => {
  useEffect(()=>{
    console.log("TimeOutModal")
  },[])
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "40%",
    bgcolor: "#fff",
    border: false,
    boxShadow: 24,
    borderRadius: "0.5em",
    p: 3,
    // m:5,
    outline: "none",
  };
  return(
    <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" >
      <Box sx={style}>
        <Grid  lg={12}  className="pt-3"  item    container flexDirection={"row"} ustifyContent={"center"} >
          <Grid item lg={12} className="pb-4" textAlign={"center"}>
            <h5 className="bold textBlack" style={{fontFamily:"InterRegular"}} >Internet connection </h5>
          </Grid>
          <Grid item lg={12} className="pb-4" textAlign={"center"}>
            <h5 className="textBlack"> Oops! It looks like something went wrong with your internet connection.</h5>
            <CircularProgress style={{color:"#000"}} />
          </Grid>
          <Grid  lg={12}  sm={12}  item  className="pr-3 pb-3" flexDirection={"row"}  container justifyContent={"space-around"} >
            <GreyColorButton   variant="contained"  style={{ width: "40%" }}  onClick={() => handleClose(true)}  > Try Again</GreyColorButton>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  )
}


export default NetworkModal;