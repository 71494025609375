import React, { useEffect } from "react";
import { Grid, Modal, Box} from "@mui/material";
import { RedColorButton, GreyColorButton } from "../materialTableStyle/StyleTableCell";
const TimeOutModal = ({ open, handleClose ,Logout}) => {
  useEffect(()=>{
    console.log("TimeOutModal")
  },[])
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "40%",
    bgcolor: "#fff",
    border: false,
    boxShadow: 24,
    borderRadius: "0.5em",
    p: 3,
    // m:5,
    outline: "none",
  };
  return(
    <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" >
      <Box sx={style}>
        <Grid  lg={12}  className="pt-3"  item    container flexDirection={"row"} ustifyContent={"center"} >
          <Grid item lg={12} className="pb-4" textAlign={"center"}>
            <h5 className="bold textBlack">Session Expired </h5>
          </Grid>
          <Grid item lg={12} className="pb-4" textAlign={"center"}>
            <h5 className="textBlack">
              It seems you have been inactive for the past 30 minutes. For security reasons, this session will automatically log out</h5>
          </Grid>
          <Grid  lg={12}  sm={12}  item  className="pr-3 pb-3" flexDirection={"row"}  container justifyContent={"space-around"} >
            <GreyColorButton   variant="contained"  style={{ width: "40%" }}  onClick={() => Logout("login")}  > Stay Logged in </GreyColorButton>
            <RedColorButton   variant="contained"  style={{ width: "40%" }}  onClick={() => Logout("logout")}  >  Log Out Now </RedColorButton>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  )
}


export default TimeOutModal;