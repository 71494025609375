import * as XLSX from "xlsx-color";
import * as FileSaver from "file-saver";
const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const fileExtension = ".xlsx";
export const csvDownload = (apiData, fileName) => {
  const workbook = XLSX.utils.book_new();
  const worksheet = XLSX.utils.aoa_to_sheet(apiData); 
  worksheet["A1"].s = { font: { bold: true } };
  worksheet["A2"].s = { font: { bold: true } };
  const columnWidths = [
    { wch: 10 }, // Width of column A is set to 15 characters
    { wch: 20 }, // Width of column B is set to 20 characters
    { wch: 20 }, // Width of column B is set to 20 characters
    { wch: 20 }, // Width of column B is set to 20 characters
    { wch: 20 }, // Width of column B is set to 20 characters
    { wch: 20 }, // Width of column B is set to 20 characters
    { wch: 20 }, // Width of column B is set to 20 characters
    { wch: 20 }, // Width of column B is set to 20 characters
    { wch: 20 }, // Width of column B is set to 20 characters
  ];

  worksheet["!cols"] = columnWidths; 
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
  const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
  const excelBlob = new Blob([excelBuffer], { type: fileType});
  FileSaver.saveAs(excelBlob, fileName + fileExtension);
};

