import React from 'react';
import { BarChart, Bar, Rectangle, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const DobleBarChart = ({S3Cost})=>{

    return (
      // <ResponsiveContainer width="100%" height="100%">
        <BarChart
          width={1000}
          height={400}
          data={S3Cost}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" vertical={false} />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="s3" fill="#4050E7"  />
          {/* <Bar dataKey="Storage" fill="#E7B840" /> */}
        </BarChart>
      // </ResponsiveContainer>
    );
}

export default DobleBarChart