import React from "react";
import { Modal, Box, Grid } from "@mui/material";
import { AiOutlineClose } from "react-icons/ai";
const RightSideModal = ({
  open,
  setOpen,
  modalContent,
  modalTitle,
  modalFooter,
  width,
  height,
  scroll,
  zIndex
}) => {
  const style = {
    position: "absolute",
    top: { md: "0%", sm: "10%", xs: "15%" },
    right: { md: "0%", sm: "10%", xs: "3%" },
    width: { md: width ? width : "50%", sm: "80%", xs: "94%" },
    bgcolor: "#fff",
    border: false,
    boxShadow: 24,
    borderRadius: "0.5em",
    outline: "none",
    overflowY: scroll ? "scroll" : "hidden",
    height: height ? height : "auto",
  };

  return (
    <Modal
      open={open}
      // onClose={() => setOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      style={{zIndex: zIndex }}
    >
      <Box sx={style} className="">
        <Grid
          container
          flexDirection={"column"}
          justifyContent={"center"}
          alignItems={"center"}
          lg={12}
          xs={12}
          md={12}
          className="GradiantButton rounded-top p-3 pb-0"
        >
          <Grid
            container
            flexDirection={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
            xs={12}
          >
            <Grid xs={11}>{modalTitle}</Grid>
            <Grid
              xs={1}
              display={"flex"}
              flexDirection={"row"}
              justifyContent={"flex-end"}
            >
              <AiOutlineClose
                onClick={() => setOpen(false)}
                style={{ cursor: "pointer" }}
                size={20}
                color="var(--icon-fill)"
                className=""
              />
            </Grid>
          </Grid>
          {/* <hr className="text-muted w-100" /> */}
          {modalContent}
        </Grid>
        {/* <hr className="text-muted w-100" /> */}
        {modalFooter && (
          <Grid
            container
            className="p-3 modal_box_shadow"
            justifyContent={"flex-end"}
          >
            {modalFooter}
          </Grid>
        )}
      </Box>
    </Modal>
  );
};

export default RightSideModal;
