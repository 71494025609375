import React from "react";
import Button from "@mui/material/Button";

function AuthBtn({
  text,
  className,
  onClick,
  style,
  btnColor,
  btnTextColor = "white",
  Icon,
  disabled,
  borderColor,
  endIcon,
  justifyContent
}) {
  return (
    <div
      style={{
        display: "flex", 
        justifyContent: justifyContent? justifyContent:  "center",
      }}
    >
      <Button
        variant="contained"
        className={`${className} ${
          btnColor ? btnColor : "GradiantButton border-1"
        } `}
        onClick={onClick}
        startIcon={Icon ? Icon : ""}
        endIcon={endIcon ? endIcon : ""}
        disabled={disabled ? disabled : false}
        sx={{
          height: "40px",
          backgroundColor: btnColor,
          color: btnTextColor,
          textTransform: "none",
          border: 1,
          borderColor: borderColor ? borderColor : btnColor,
          borderRadius:"8px",
          "&:hover": {
            backgroundColor: btnColor, // or any other desired color
          },
        }}
        style={{ style }}
      >
        <span
          className=""
          style={{ fontSize: "16px", fontFamily: "InterSemiBold" ,  }}
        >
          {text}
        </span>
      </Button>
    </div>
  );
}

export default AuthBtn;
