import React, { useEffect, useState } from "react";
import Menu from "@mui/material/Menu";
import MenuItem, { menuItemClasses } from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import NotificationsIcon from "@mui/icons-material/Notifications";
import ArticleIcon from "@mui/icons-material/Article";
import Badge from "@mui/material/Badge";
import { styled } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { getMethod ,postMethod} from "../../Components/Helper/api";
import { DateAndTime } from "../../Components/DateFormats/DateFromat"

const NotificationS = () => {
  const navigate = useNavigate()
  const authtoken = localStorage.getItem("@accessToken")
  const [anchorEl, setAnchorEl] = useState(null);
  const [NotificationsTable, setNotifications] = useState([]);
  const openAvatar = Boolean(anchorEl);
  useEffect(() => {
    GetNotifications()
  }, [])
  const GetNotifications = async () => {
    try {
      let url = "notifications/listAdminNotifications"
      let method = "GET"
      const response = await getMethod({ url, method, authtoken })
      console.log("listAdminNotifications",response)
      if (response.status) {
        if (response.result) {
          // setNotifications(response.result)
        }
      }
    } catch (Error) {
      console.log("GetNotifications Error", Error)
    }
  }
  const NotificationChanges =async(item)=>{
    console.log("item",item)
    if(item.notification_category=="Ticket Received"){
      navigate("/tickets")
    }else if(item.notification_category=="Payment Received"){
      navigate("/billing")
    }else if(item.notification_category=="Enterprise Request"){
      navigate("/inquiry")
    }else if(item.notification_category=="Delete Request"){
      navigate("/clients")
    }
    try{
      if(item.notifications_id){
        let url = "notifications/readAdminNotifications";
        let method = "PUT"
        let params = {
          "id": item.notifications_id
        };
        let response = await postMethod({ url, params, authtoken, method });
        if (response.status == true) {
          GetNotifications();
        }
      }
     
    }catch(Error){
      console.log("Read Notification",Error)
    }
   
  }
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseAvatar = () => {
    setAnchorEl(null);
  };
  const MenuCell = styled(MenuItem)(() => ({
    [`&.${menuItemClasses.root}`]: {
      fontFamily: "InterMedium",
      borderBottom: "1px solid #ACB0B1",
      display: "flex",
      justifyContent: "space-between",
      paddingTop: "3%",
      paddingBottom: "3%",
      fontSize: "12px",
    },
  }));
  const MenuCellAnother = styled(MenuItem)(() => ({
    [`&.${menuItemClasses.root}`]: {
      fontFamily: "InterMedium",
      borderBottom: "1px solid #ACB0B1",
      display: "flex",
      justifyContent: "flex-start",
      // position:"fixed",
      paddingTop: "3%",
      paddingBottom: "3%",
      fontSize: "18px",
    },
  }));
  const BatchCustom = styled(Badge)(() => ({
    ".MuiBadge-badge": {
      fontFamily: "InterMedium",
      backgroundColor: "#D84B10",
      color: "#fff",
    },
  }));


  return (
    <React.Fragment>
      <Tooltip title="Notifications">
        <IconButton
          className="textBlack"
          onClick={handleClick}
          // size="medium"
          sx={{ ml: 2 }}
          aria-controls={openAvatar ? "account-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={openAvatar ? "true" : undefined}
        >
          <BatchCustom
            badgeContent={NotificationsTable.length}
            overlap="circular"
          >
            <NotificationsIcon sx={{ color: "#000" }} />
          </BatchCustom>
        </IconButton>
      </Tooltip>

      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={openAvatar}
        onClose={handleCloseAvatar}
        onClick={handleCloseAvatar}
        PaperProps={{
          elevation: 0,
          sx: {
            // overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            // bgcolor:"red",
            maxHeight: "35%",
            // mt: 1.5,
            "& .MuiAvatar-root": {
              width: 40,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            ".MuiList-root": {
              padding: 0,
            },
            "&:before": {
              content: "''",
              display: "block",
              position: "absolute",
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuCellAnother>
          <NotificationsIcon fontSize="medium" className="textBlack" />
          <p className="semiBold pr-3 pl-3">Notifications</p>
        </MenuCellAnother>
        {NotificationsTable.length > 0 ? (
          NotificationsTable.map((item, i) => {
            return (
              <MenuCell key={i} onClick={() => NotificationChanges(item)} style={{ justifyContent: "flex-start" }} >
                <ArticleIcon className="textBlack" fontSize="small" />
                <div style={{flexDirection:"column"}}>
                  <p className="medium pr-3 pl-3">
                    {item.notification_description}
                  </p>
                  <p className="regular  textColorGray pr-3 pl-3">{DateAndTime(Number(item.created_at))}
                  </p>
                </div>

              </MenuCell>
            );
          })
        ) : (
          <MenuCell>
            {/* <NotificationsIcon fontSize="medium" className="textBlack"/>  */}
            <p className="semiBold pr-3 pl-3">No Notifications Available</p>
          </MenuCell>
        )}
      </Menu>
    </React.Fragment>
  );
};
export default NotificationS;
