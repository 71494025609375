import { CircularProgress, Grid, } from "@mui/material";
import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import { useNavigate } from "react-router-dom";

import { RiskStyledTableCell, StyledTableRow, CustomTablePagination } from "../../../Components/materialTableStyle/StyleTableCell";
import { formatedDateAndTime } from "../../../Components/DateFormats/DateFromat"
import AuthBtn from "../../../Components/Button/AuthBtn";
import ConfirmationModal from "../../../Components/Modal/ConfirmationModal"
import { postMethod } from "../../../Components/Helper/api"
import CustomInput from "../../../Components/Input/CustomInput";
import { toast } from "react-toastify";
const JenkinsTable = ({ ClientsData, Typeof, GetClientData, GetExitRequestData, Job_name }) => {
    const authtoken = localStorage.getItem("@accessToken")
    const Admin = JSON.parse(localStorage.getItem("@users"))

    useEffect(() => {
        console.log("Job_name", Job_name)
    }, [Job_name])
    const navigate = useNavigate();
    const [InitialData, setInitialData] = useState([]);
    const [Loader, setLoader] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);    const [Open, setOpen] = useState(false);

    const [EmailModal, setEmailModal] = useState(false);
    const [EmailAddress, setEmailAddress] = useState("");
    const [JenkinsConsole, setJenkinsConsole] = useState("")

    const validationemails = (email) => {
        /* eslint-disable */
        const regExp =
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return regExp.test(email);
         /* eslint-enable */
    };

    useEffect(() => {
        setInitialData(ClientsData);
        setLoader(true);
    }, [ClientsData]);

    useEffect(() => {
        if (Loader) {
            setTimeout(() => {
                setLoader(false);
            }, 1000);
        }
    }, [Loader]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 5));
        setPage(0);
    };


    const GetClientDatas = (Datas) => {
        console.log("Datas", Datas)
        navigate(`/clientdetails/${Datas.tenants_id}`, { state: Datas });
    };

    const HandleSideModal = (number) => {
        GetConsoleDetails(number)
    };

    const GetDuration = (durations) => {
        let duration = Number(durations);
        duration = duration / 1000
        let hours = Math.floor(duration / 3600);
        let minutes = Math.floor((duration % 3600) / 60);
        let seconds = Math.floor(duration % 60);
        let formattedDuration = hours + 'H : ' + minutes + 'M: ' + seconds + "Sec";
        return formattedDuration
    }

    const GetConsoleDetails = async (number) => {
        try {
            let url = "log/GETBuildConsole";
            let method = "post"
            let params = {
                "buildid": number
            }
            let response = await postMethod({ url, authtoken, method, params });
            if (response.status) {
                setJenkinsConsole(response.result)
                console.log("response", response)
                setOpen(true)
            }
        } catch (Error) {
            console.log("Error", Error)
        }
    }
    const getPDFdata = async () => {
        console.log("PDF")
    }

    const getEmaildata = async () => {
        console.log("Email")
        if (!validationemails(EmailAddress)) {
            toast.warn("Please enter valid email address")
            return
        }
        try {
            let url = "log/EmailConsole";
            let method = "post"
            let params = {
                "email": EmailAddress,
                consoledata: JenkinsConsole
            }
            let response = await postMethod({ url, authtoken, method, params });
            console.log("response", response)
            if (response.status) {
                setOpen(false)
                setJenkinsConsole("")
                setEmailAddress("")
                setEmailModal(false)
            } else {
                setEmailAddress("")
                toast.warn("Something went wrong, please try again")
            }
        } catch (Error) {
            setEmailAddress("")
            toast.warn("Something went wrong, please try again")
            console.log("Error", Error)
        }
    }
    return (
        <Grid
            container
            lg={12}
            display={"flex"}
            flexDierection={"column"}
            alignItems={"flex-start"}
            justifyContent={"flex-start"}
        >
            <Grid
                container
                lg={12}
                display={"flex"}
                flexDirection={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
                className="mt-4"
            >
                <Grid
                    lg={2.5}
                    display={"flex"}
                    flexDirection={"row"}
                    alignItems={"center"}
                >
                    <h5 className="font-weight-bold">
                        Jenkins
                    </h5>
                </Grid>
                <Grid
                    container
                    lg={9.5}
                    display={"flex"}
                    flexDierection={"row"}
                    alignItems={"center"}
                    justifyContent={"flex-end"}
                >
                </Grid>
            </Grid>
            <Grid container lg={12} className="mt-4">
                <TableContainer>
                    <Table
                        stickyHeader
                        aria-label="sticky table"
                    >
                        <TableHead className="rounded">
                            <StyledTableRow>
                                <RiskStyledTableCell>Build</RiskStyledTableCell>
                                <RiskStyledTableCell align="left">
                                    Status{" "}
                                </RiskStyledTableCell>
                                <RiskStyledTableCell align="left">
                                    Created Time{" "}
                                </RiskStyledTableCell>
                                <RiskStyledTableCell>
                                    Time Since
                                </RiskStyledTableCell>
                                <RiskStyledTableCell>
                                    Action
                                </RiskStyledTableCell>
                            </StyledTableRow>
                        </TableHead>
                        {!Loader ? (
                            InitialData?.length > 0 ? (
                                <TableBody>
                                    {InitialData?.slice(
                                        page * rowsPerPage,
                                        page * rowsPerPage + rowsPerPage
                                    ).map((row, i) => (
                                        <StyledTableRow key={i}>
                                            <RiskStyledTableCell
                                                align="left"
                                                onClick={() =>
                                                    Typeof === "Clients" ? GetClientDatas(row) : ""
                                                }
                                                sx={{ cursor: "pointer" }}
                                            >
                                                <Grid
                                                    container
                                                    display={"flex"}
                                                    flexDierection={"row"}
                                                    alignItems={"center"}
                                                    justifyContent={"flex-start"}
                                                >
                                                    {/* <Avatar src={row.business_logo} /> */}
                                                    <Grid className="mx-2">
                                                        <p> {Job_name} # {row?.number} </p>
                                                    </Grid>
                                                </Grid>
                                            </RiskStyledTableCell>
                                            <RiskStyledTableCell>
                                                <p style={{ cursor: "pointer", color: row.result == "FAILURE" ? "red" : "green" }}>
                                                    {row.result}
                                                </p>
                                            </RiskStyledTableCell>
                                            <RiskStyledTableCell>
                                                <p style={{ cursor: "pointer" }}>
                                                    {formatedDateAndTime(row.timestamp)}
                                                </p>
                                            </RiskStyledTableCell>
                                            <RiskStyledTableCell>
                                                <p style={{ cursor: "pointer" }}>
                                                    {GetDuration(row.duration)}
                                                </p>
                                            </RiskStyledTableCell>
                                            <RiskStyledTableCell>
                                                <AuthBtn
                                                    text={"Build Console"}
                                                    btnColor={"#4EAD5B"}
                                                    btnTextColor="#fff"
                                                    onClick={() => HandleSideModal(row?.number)}
                                                />
                                            </RiskStyledTableCell>
                                        </StyledTableRow>
                                    ))}
                                </TableBody>
                            ) : (
                                <TableBody>
                                    <StyledTableRow>
                                        <RiskStyledTableCell colSpan={6} align="center">
                                            <p className="text-center">No Clients Available</p>
                                        </RiskStyledTableCell>
                                    </StyledTableRow>
                                </TableBody>
                            )
                        ) : (
                            <TableBody>
                                <StyledTableRow>
                                    <RiskStyledTableCell colSpan={6} align="center">
                                        <span className="regular">
                                            <CircularProgress style={{ color: "#000" }} />
                                        </span>
                                    </RiskStyledTableCell>
                                </StyledTableRow>
                            </TableBody>
                        )}
                    </Table>

                    {InitialData?.length > 5 && (
                        <Grid
                            container
                            lg={12}
                            xl={12}
                            xs={12}
                            justifyContent={"flex-end"}
                            alignItems={"flex-end"}
                            style={{ backgroundColor: "#FAFAFA" }}
                        >
                            <CustomTablePagination
                                className="paginationintable w-100"
                                component="div"
                                // rowsPerPageOptions={[10, 25, 50, { value: -1, label: "All" }]}
                                count={InitialData.length}
                                page={page}
                                rowsPerPage={rowsPerPage}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                labelRowsPerPage=""
                            />
                        </Grid>
                    )}
                </TableContainer>
            </Grid>

            <ConfirmationModal
                open={Open}
                setOpen={setOpen}
                width={"45%"}
                modalTitle={<h5 className="font-weight-bold">Console Output</h5>}
                modalContent={
                    <Grid
                        container
                        lg={12}
                        display={"flex"}
                        flexDirection={"column"}
                        alignItems={"flex-start"}
                    >
                        <Grid
                            container
                            lg={12}
                            xs={12}
                            className="input_bg mt-2"
                            alignItems="center"
                            justifyContent="flex-end"
                        >
                            <textarea className="Textarea  textAreaHeight" value={JenkinsConsole}
                                maxLength={2000} rows={9} style={{ backgroundColor: "#F2F2F2", padding: 5 }}
                            />
                        </Grid>
                        <hr className="w-100" />
                        <Grid
                            container
                            lg={12}
                            display={"flex"}
                            flexDirection={"row"}
                            alignItems={"center"}
                            justifyContent={"center"}
                            className="mt-4"
                        >
                            <AuthBtn
                                text={"Download PDF"}
                                btnColor={"#4EAD5B"}
                                btnTextColor="#fff"
                                onClick={() => getPDFdata()}
                            />
                            <div className="p-2" />
                            <AuthBtn
                                text={"Send Report to Mail"}
                                btnColor={"#6F98B6"}
                                btnTextColor="#fff"
                                onClick={() => setEmailModal(true)}
                            />
                        </Grid>
                    </Grid>
                }
            />
            <ConfirmationModal
                open={EmailModal}
                setOpen={setEmailModal}
                width={"45%"}
                modalTitle={<h5 className="font-weight-bold">Enter Your Email</h5>}
                modalContent={
                    <Grid
                        container
                        lg={12}
                        display={"flex"}
                        flexDirection={"column"}
                        alignItems={"flex-start"}
                    >
                        <Grid
                            container
                            lg={8}
                            xs={8}
                            className="input_bg mt-2"
                            alignItems="center"
                            justifyContent="flex-end"
                        >
                            <CustomInput
                                placeholder={"Email Address"}
                                Value={EmailAddress}
                                // StartIcon={<CiSearch size={"20px"} />}
                                height={"50px"}
                                handleInput={(e) => setEmailAddress(e)}
                            />
                        </Grid>
                        <hr className="w-100" />
                        <Grid
                            container
                            lg={12}
                            display={"flex"}
                            flexDirection={"row"}
                            alignItems={"center"}
                            justifyContent={"center"}
                            className="mt-4"
                        >
                            <div className="p-2" />
                            <AuthBtn
                                text={"Send Report to Mail"}
                                btnColor={"#6F98B6"}
                                btnTextColor="#fff"
                                onClick={() => getEmaildata()}
                            />
                        </Grid>
                    </Grid>
                }
            />
        </Grid>
    );
};

export default JenkinsTable;
