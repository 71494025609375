import React from "react";
import { Modal, Box, Grid } from "@mui/material";
import { AiOutlineClose } from "react-icons/ai";
const ConfirmationModal = ({
  open,
  setOpen,
  modalContent,
  width,
  modalTitle,
  transparent,
  height,
  scrollable,
  zindex
}) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: width ? width : "30%",
    height: height ? height : "auto",
    overflowY: scrollable ? "scroll" : "hidden",
    bgcolor: transparent ? "rgba(0, 0, 0, 0.1)" : "#fff",
    border: false,
    boxShadow: 24,
    borderRadius: "0.5em",
  };

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      style={{zIndex:zindex}}
    >
      <Box sx={style} className="">
        <Grid
          container
          flexDirection={"column"}
          justifyContent={"center"}
          alignItems={"center"}
          lg={12}
          xs={12}
          md={12}
          className="GradiantButton rounded-top "
        >
          <Grid
            container
            flexDirection={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
            className="p-3 bg-white rounded-top"
          >
            {modalTitle}
            <AiOutlineClose
              onClick={() => setOpen(false)}
              style={{ cursor: "pointer", color: "#000" }}
              size={20}
              color="#fff"
              className="mx-2"
            />
          </Grid>
        </Grid>
        <hr className="w-100 m-0 p-0" />
        <Grid className={transparent ? "" : "p-3"}>{modalContent}</Grid>
      </Box>
    </Modal>
  );
};

export default ConfirmationModal;
