import { Grid, Paper } from "@mui/material";
import React, { useEffect, useState } from "react";

import LogsTable from "./Table";
import { getMethod } from "../../Components/Helper/api"

const Clients = () => {
  const [InquiryType, setInquiryType] = useState("ClientExit");
  const authtoken = localStorage.getItem("@accessToken")
  const [ClientMainDataS , setClientMainDataS]=useState([])

  useEffect(()=>{
    GetExitRequestData()
  },[])

  const GetExitRequestData = async () => {
    try {
      let url = "log/Listlog";
      let method = "GET"
      let response = await getMethod({ url, authtoken, method });
      if (response.status) {
        console.log("GetExitRequestData",response)
        setClientMainDataS(response.result)
      }
    } catch (Error) {
      console.log("GetInqueriryData", Error)
    }
  }
  return (
    <Grid
      container
      lg={12}
      display={"flex"}
      flexDirection={"column"}
      alignItems={"center"}
      justifyContent={"center"}
    >
      <Paper className="w-100  p-3">
        <Grid
          container
          lg={12}
          display={"flex"}
          flexDirection={"row"}
          alignItems={"center"}
          justifyContent={"flex-start"}
          className="mt-3"
        >
        </Grid>
        <Grid container lg={12}>
          <LogsTable ClientsData={ ClientMainDataS } Typeof={InquiryType} GetExitRequestData={GetExitRequestData} />
        </Grid>
      </Paper>
    </Grid>
  );
};

export default Clients;
