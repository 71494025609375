import { Grid, Paper } from "@mui/material";
import React, { useEffect, useState } from "react";

import { FormatDateToDate } from "../../Components/DateFormats/DateFromat"
import { getMethod } from "../../Components/Helper/api"
import Barchart from "./BarChart/Barchart"
import Linechart from "./BarChart/LineChart"
import DobleBarChart from "./BarChart/DoubleBarChart"
import AuthBtn from "../../Components/Button/AuthBtn";

const Clients = () => {
    const authtoken = localStorage.getItem("@accessToken")

    const [InstanceDetails, setInstanceDetails] = useState()
    const [InstanceDetails1, setInstanceDetails1] = useState()
    const [ServiceCost, setServiceCost] = useState([])
    const [CPUUtilization, setCPUUtilization] = useState([])
    const [Total_CPU_Utilization, setTotal_CPU_Utilization] = useState([])
    const [S3Details, setS3Details] = useState([]) //s3_details
    const [S3Cost, setS3Cost] = useState([]) //s3_details

    useEffect(() => {
        GetInstanceData()
        GetCostDetails()
        GetCpuData()
    }, [])

    const ConvertTime = (time) => {
        const timestamp = time;
        const dateObj = new Date(timestamp);
        var time = dateObj.toLocaleTimeString("en-US", { hour: "2-digit", minute: "2-digit" });
        return time
    }

    const GetInstanceData = async () => {
        try {
            let url = "log/getawsInstanceDetails";
            let method = "GET"
            let response = await getMethod({ url, authtoken, method });
            if (response.status) {
                console.log("GetInstanceData",response)
                setInstanceDetails(response.result[0])
                setInstanceDetails1(response?.Instance[0])
                setS3Details(response.s3_details?.Buckets)
            }
        } catch (Error) {
            console.log("GetInstanceData", Error)
        }
    }

    const GetCostDetails = async () => {
        try {
            let url = "log/GetCostDetails";
            let method = "GET"
            let response = await getMethod({ url, authtoken, method });
            if (response.status) {
                // console.log("GetCostDetails", response)
                let date = ["Aug 2023", "Sep 2023", "Oct 2023", "Nov 2023", "Dec 2023", "Jan 2024", "Feb 2024",]
                let Array = []
                let S3_details = []
                if (response?.result?.ResultsByTime) {
                    console.log("S3_cost", response)
                    response?.result?.ResultsByTime.map((cost, i) => {
                        let data = {
                            name: date[i],
                            EC2_Other: 0,
                            EC2_Instances: 0,
                            s3: 0,
                            Key_Management_service: 0,
                            Tax: 0,
                            Rourer53: 0,
                            Amplify: 0,
                            SNS: 0,
                            Registrar: 0
                        }
                        cost.Groups?.map((services) => {
                            if (services.Keys[0] == "AWS Amplify") {
                                data.Amplify = Number(services?.Metrics?.BlendedCost.Amount)
                            } else if (services.Keys[0] == "EC2 - Other") {
                                data.EC2_Other = Number(services?.Metrics?.BlendedCost.Amount)
                            } else if (services.Keys[0] == "Amazon Elastic Compute Cloud - Compute") {
                                data.EC2_Instances = Number(services?.Metrics?.BlendedCost.Amount)
                            } else if (services.Keys[0] == "Amazon Simple Notification Service") {
                                data.SNS = Number(services?.Metrics?.BlendedCost.Amount)
                            } else if (services.Keys[0] == "Amazon Simple Storage Service") {
                                S3_details.push({
                                    name: date[i],
                                    s3: Number(services?.Metrics?.BlendedCost.Amount)
                                })
                                data.s3 = Number(services?.Metrics?.BlendedCost.Amount)
                            } else if (services.Keys[0] == "Tax") {
                                data.Tax = Number(services?.Metrics?.BlendedCost.Amount)
                            } else if (services.Keys[0] == "Amazon Route 53") {
                                data.Rourer53 = Number(services?.Metrics?.BlendedCost.Amount)
                            } else if (services.Keys[0] == "AWS Key Management Service") {
                                data.Key_Management_service = Number(services?.Metrics?.BlendedCost.Amount)
                            } else if (services.Keys[0] == "Amazon Registrar") {
                                data.Registrar = Number(services?.Metrics?.BlendedCost.Amount)
                            }
                        })
                        Array.push(data)
                    })
                    setServiceCost(Array)
                    // console.log("S3_cost",S3_details)
                    setS3Cost(S3_details)
                }
            }
        } catch (Error) {
            console.log("GetCostDetails", Error)
        }
    }
    const GetCpuData = async () => {
        try {
            let url = "log/GetcpuUtilization";
            let method = "GET"
            let response = await getMethod({ url, authtoken, method });
            if (response.status) {
                let TimeStamp = response.result.Timestamps
                let Array = []
                let values = response.result.Values
                let datehr = (60 / 5) * 24 // 1 hr
                TimeStamp.map((time, index) => {
                    let newtime = ConvertTime(String(time))
                    let date = FormatDateToDate(String(time))
                    Array.push({
                        name: newtime,
                        time: newtime,
                        value: values[index],
                        date: date
                    })
                })
                setTotal_CPU_Utilization(Array)
                let new_Array = Array.slice(0, datehr)
                new_Array.reverse()
                setCPUUtilization(new_Array)
                // setInstanceDetails(Array.slice(0,datehr))
            }
        } catch (Error) {
            console.log("GetInstanceData", Error)
        }
    }


    const FilterData = (data) => {
        if (data == "12h") {
            let datehr = (60 / 5) * 12 // 1 hr
            let new_Array = Total_CPU_Utilization.slice(0, datehr)
            new_Array.reverse()
            setCPUUtilization(new_Array)
        } else if (data == "day") {
            let datehr = (60 / 5) * 24// 1 hr
            let new_Array = Total_CPU_Utilization.slice(0, datehr)
            new_Array.reverse()
            setCPUUtilization(new_Array)
        } else if (data == "week") {
            let datehr = (60 / 5) * 24 * 7
            let new_Array = Total_CPU_Utilization.slice(0, datehr)
            new_Array.reverse()
            setCPUUtilization(new_Array)
        }
    }

    // const getLatUpdatedate = (lastdate) => {
    //     if (lastdate) {
    //         let today = Date.now()
    //         let difference = Number(today) - Number(lastdate)
    //         let days = Math.floor(difference / (1000 * 60 * 60 * 24));
    //         let hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    //         let minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
    //         let formattedDifference = days + 'D : ' + hours + 'H : ' + minutes + 'M';
    //         return formattedDifference
    //     } else {
    //         return ""
    //     }
    // }
    return (
        <Grid
            container
            lg={12}
            display={"flex"}
            flexDirection={"column"}
            alignItems={"center"}
            justifyContent={"center"}
        >
            <Paper className="w-100  p-3">
                <Grid
                    container
                    lg={12}
                    display={"flex"}
                    flexDirection={"column"}
                    alignItems={"center"}
                    justifyContent={"flex-start"}
                    className="mt-3"
                >
                </Grid>
                <Grid container style={{ padding: 10, marginTop: 10 }} lg={12}>
                    <Paper className="w-100  p-3">
                        <h5 className="font-weight-bold" style={{ textAlign: "start", marginTop: 10 }} > Instance Summary</h5>
                        <Grid lg={12} style={{ flexDirection: "row", display: "flex" }}>
                            <Grid lg={4} >
                                <p>Instance Name:  {InstanceDetails && InstanceDetails?.KeyName}</p>
                                <p>Public IPv4 Address :  {InstanceDetails && InstanceDetails?.PublicIpAddress} </p>
                            </Grid>
                            <Grid lg={4} >
                                <p>Instance ID :  {InstanceDetails && InstanceDetails?.InstanceId}</p>
                                <p style={{ flexDirection: "row", display: "flex" }} >Instance state : {" "} <p style={{ textTransform: "capitalize", color: InstanceDetails?.State?.Name == "running" ? "green" : "red" }} > {" "} {InstanceDetails && InstanceDetails?.State?.Name}  </p> </p>
                            </Grid>
                            <Grid lg={4} >
                                <p>Instance Type :  {InstanceDetails && InstanceDetails?.InstanceType}</p>
                                <p>Instance Type :  {InstanceDetails1 && InstanceDetails1?.Placement?.AvailabilityZone}</p>
                            </Grid>
                        </Grid>
                        <h5 className="font-weight-bold" style={{ textAlign: "start", marginTop: 10 }} > Backup Instance Summary</h5>
                        <Grid lg={12} style={{ flexDirection: "row", display: "flex",marginTop:10 }}>
                            <Grid lg={4} >
                                <p>Instance Name:  {InstanceDetails1 && InstanceDetails1?.KeyName}</p>
                                <p>Public IPv4 Address :  {InstanceDetails1 && InstanceDetails1?.PublicIpAddress} </p>
                            </Grid>
                            <Grid lg={4} >
                                <p>Instance ID :  {InstanceDetails1 && InstanceDetails1?.InstanceId}</p>
                                <p style={{ flexDirection: "row", display: "flex" }} >Instance state : {" "} <p style={{ textTransform: "capitalize", color: InstanceDetails1?.State?.Name == "running" ? "green" : "red" }} > {" "} {InstanceDetails && InstanceDetails?.State?.Name}  </p> </p>
                            </Grid>
                            <Grid lg={4} >
                                <p>Instance Type :  {InstanceDetails1 && InstanceDetails1?.InstanceType}</p>
                                <p>Instance Type :  {InstanceDetails1 && InstanceDetails1?.Placement?.AvailabilityZone}</p>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                <hr className="w-100 text-muted m-0 p-0" />
                <h5 className="font-weight-bold" style={{ textAlign: "start", marginTop: 10 }} > Cost breakdown</h5>
               
                <Grid container lg={12} style={{ justifyContent: "center", padding: 10 }} >
                    <Paper className="w-100  p-3" style={{ justifyContent: "center", display: "flex" }} >
                        <Barchart ServiceCost={ServiceCost} />
                    </Paper>
                </Grid>
                {/* <hr className="w-100 text-muted m-0 p-0" /> */}

              

                <Grid container lg={12} style={{ padding: 10 }} >
                    <h5 className="font-weight-bold" style={{ textAlign: "start", marginTop: 10 }} > Monitoring</h5>
                    <Paper className="w-100  p-3">
                        <Grid style={{ display: "flex", flexDirection: "row", alignSelf: "flex-end", justifyContent: "flex-end", margin: 3 }} >
                            <AuthBtn
                                btnColor={"#fff"}
                                btnTextColor="var(--light_Brown_color)"
                                text={"12h"}
                                borderColor={"var(--light_Brown_color)"}
                                className="p-2 w-100 m-2"
                                onClick={() => FilterData("12h")}
                            />
                            <AuthBtn
                                btnColor={"#fff"}
                                btnTextColor="var(--light_Brown_color)"
                                text={"Day"}
                                borderColor={"var(--light_Brown_color)"}
                                className="p-2 w-100 m-2"
                                onClick={() => FilterData("day")}
                            />
                            <AuthBtn
                                btnColor={"#fff"}
                                btnTextColor="var(--light_Brown_color)"
                                text={"week"}
                                borderColor={"var(--light_Brown_color)"}
                                className="p-2 w-100 m-2"
                                onClick={() => FilterData("week")}
                            />
                        </Grid>
                        <Grid lg={12} style={{ padding: 10, display: "flex", justifyContent: "center" }} >
                            <Linechart ServiceCost={CPUUtilization} />
                        </Grid>
                    </Paper>
                </Grid>

                <Grid container style={{ padding: 10, marginTop: 10 }} lg={12}>
                    <Paper className="w-100  p-3">
                        <h5 className="font-weight-bold" style={{ textAlign: "start", marginTop: 10 }} > S3 </h5>
                        {
                            S3Details?.map((buccket) => {
                                return (
                                    <Grid lg={12} style={{ flexDirection: "row", display: "flex" }}>
                                        <>
                                            <Grid lg={4} >
                                                <p>Bucket Name : {buccket.Name} </p>
                                                <p></p>
                                            </Grid>
                                            <Grid lg={4} >
                                                <p>Region :  ap-southeast-2</p>
                                                {/* <p style={{ flexDirection: "row", display: "flex" }} >Instance state : {" "} <p style={{ textTransform: "capitalize", color: InstanceDetails?.State?.Name == "running" ? "green" : "red" }} > {" "} {InstanceDetails && InstanceDetails?.State?.Name}  </p> </p> */}
                                            </Grid>
                                            <Grid lg={4} >
                                                <p>Created Date : {" "}{buccket.CreationDate}</p>
                                            </Grid>
                                        </>
                                    </Grid>
                                )
                            })
                        }
                    </Paper>
                </Grid>

                <Grid container lg={12} style={{ padding: 10 }} >
                    <h5 className="font-weight-bold" style={{ textAlign: "start", marginTop: 10 }} > Cost breakdown for S3 </h5>
                    <Paper className="w-100  p-3">

                        <Grid lg={12} style={{ padding: 10, display: "flex", justifyContent: "center" }} >
                            <DobleBarChart S3Cost={S3Cost} />
                        </Grid>
                    </Paper>
                </Grid>
            
            </Paper>
        </Grid>
    );
};

export default Clients;
