import { Grid, Paper } from "@mui/material";
import React, { useEffect, useState } from "react";

import TicketsHomeTable from "./Tables/TicketsHomeTable";
import { getMethod } from "../../Components/Helper/api";
import { useDispatch } from "react-redux";
const TicketsHome = () => {
  const authtoken = localStorage.getItem("@accessToken")
  const  dispatch = useDispatch()
  const [TicketsHomeData, setTicketsHomeData] = useState([])
  const [TicketsPending, setTicketsPending] = useState(0)
  const [TicketsCompleted, setTicketsCompleted] = useState(0)
  useEffect(() => {
    GetTicketList()
  }, [])
  const GetTicketList = async () => {
    try {
      let method = "GET"
      let url = "ticket/maintenanceList"
      const response = await getMethod({ method, url, authtoken })
      if (response.status) {
        if (response.result) {
          setTicketsHomeData(response.result)
          var pending = 0
          var completed = 0
          response.result.map((tickets) => {
            if (tickets.ticket_update_status == "open") {
              pending = pending + 1
            }
            if (tickets.ticket_update_status == "closed") {
              completed = completed + 1
            }
            dispatch({
              type: "TICKET_COUNT",
              payload: { ticketcount: pending }
            })
            setTicketsPending(pending)
            setTicketsCompleted(completed)
          })
        }
      }

    } catch (Error) {
      console.log("GetTicketList Error", Error)
    }
  }
  return (
    <Grid
      container
      lg={12}
      display={"flex"}
      flexDirection={"column"}
      alignItems={"flex-start"}
      justifyContent={"flex-start"}
    >
      <Paper className="p-3 w-100" sx={{ backgroundColor: "#444444" }}>
        <Grid
          container
          lg={12}
          display={"flex"}
          flexDirection={"row"}
          alignItems={"stretch"}
          justifyContent={{ sm: "space-between", lg: "space-around" }}
          className=""
        >
          <Grid
            item
            lg={3.7}
            md={3.9}
            sm={5.5}
            className="Card_image2 p-4 w-100 rounded"
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"flex-start"}
            alignItems="flex-start "
          >
            <Grid
              container
              lg={12}
              display={"flex"}
              flexDirection={"column"}
              justifyContent={"flex-start"}
              alignItems="flex-start"
            >
              <p
                className="font-weight-bold"
                style={{ color: "#F8FFA8", fontSize: "17px" }}
              >
                Total Tickets
              </p>
              <h2
                className="font-weight-bold mt-4"
                style={{ color: "#F8FFA8" }}
              >
                {TicketsHomeData?.length}
              </h2>
            </Grid>
            <Grid
              container
              lg={12}
              display={"flex"}
              flexDirection={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
              className="mt-3"
            >
              <Grid
                display={"flex"}
                flexDirection={"column"}
                alignItems={"flex-start"}
                justifyContent={"flex-start"}
              >
                <p style={{ color: "#F8FFA8" }}>This month</p>
                <h5
                  className="font-weight-bold mt-2"
                  style={{ color: "#F8FFA8" }}
                >
                   {TicketsHomeData?.length}
                </h5>
              </Grid>
              <Grid
                display={"flex"}
                flexDirection={"column"}
                alignItems={"flex-start"}
                justifyContent={"flex-start"}
              >
                <p style={{ color: "#F8FFA8" }}>This Year</p>
                <h5
                  className="font-weight-bold mt-2"
                  style={{ color: "#F8FFA8" }}
                >
                   {TicketsHomeData?.length}
                </h5>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            lg={3.7}
            md={3.9}
            sm={5.5}
            className="Card_image2 p-4 w-100 rounded "
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"flex-start"}
            alignItems="flex-start "
          >
            <Grid
              container
              lg={12}
              display={"flex"}
              flexDirection={"column"}
              justifyContent={"flex-start"}
              alignItems="flex-start"
            >
              <p
                className="font-weight-bold"
                style={{ color: "#F8FFA8", fontSize: "17px" }}
              >
                Pending Tickets
              </p>
              <h2
                className="font-weight-bold mt-4"
                style={{ color: "#F8FFA8" }}
              >
                {TicketsPending}
              </h2>
            </Grid>
            <Grid
              container
              lg={12}
              display={"flex"}
              flexDirection={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
              className="mt-3"
            >
              <Grid
                display={"flex"}
                flexDirection={"column"}
                alignItems={"flex-start"}
                justifyContent={"flex-start"}
              >
                <p style={{ color: "#F8FFA8" }}>This month</p>
                <h5
                  className="font-weight-bold mt-2"
                  style={{ color: "#F8FFA8" }}
                >
                  {TicketsPending}
                </h5>
              </Grid>
              <Grid
                display={"flex"}
                flexDirection={"column"}
                alignItems={"flex-start"}
                justifyContent={"flex-start"}
              >
                <p style={{ color: "#F8FFA8" }}>This Year</p>
                <h5
                  className="font-weight-bold mt-2"
                  style={{ color: "#F8FFA8" }}
                >
                  {TicketsPending}
                </h5>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            lg={3.7}
            md={3.9}
            sm={5.5}
            className="Card_image3 p-4 w-100 rounded"
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"flex-start"}
            alignItems="flex-start "
          >
            <Grid
              container
              lg={12}
              display={"flex"}
              flexDirection={"column"}
              justifyContent={"flex-start"}
              alignItems="flex-start"
            >
              <p
                className="font-weight-bold"
                style={{ color: "#F8FFA8", fontSize: "17px" }}
              >
                Completed Tickets
              </p>
              <h2
                className="font-weight-bold mt-4"
                style={{ color: "#F8FFA8" }}
              >
                {TicketsCompleted}
              </h2>
            </Grid>
            <Grid
              container
              lg={12}
              display={"flex"}
              flexDirection={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
              className="mt-3"
            >
              <Grid
                display={"flex"}
                flexDirection={"column"}
                alignItems={"flex-start"}
                justifyContent={"flex-start"}
              >
                <p style={{ color: "#F8FFA8" }}>This month</p>
                <h5
                  className="font-weight-bold mt-2"
                  style={{ color: "#F8FFA8" }}
                >
                  {TicketsCompleted}
                </h5>
              </Grid>
              <Grid
                display={"flex"}
                flexDirection={"column"}
                alignItems={"flex-start"}
                justifyContent={"flex-start"}
              >
                <p style={{ color: "#F8FFA8" }}>This Year</p>
                <h5
                  className="font-weight-bold mt-2"
                  style={{ color: "#F8FFA8" }}
                >
                  {TicketsCompleted}
                </h5>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>

      <Grid container lg={12} className="mt-3">
        <TicketsHomeTable TicketsData={TicketsHomeData} GetTicketList={GetTicketList} />
      </Grid>
    </Grid>
  );
};

export default TicketsHome;
