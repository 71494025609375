import { Button, TablePagination, TableRow } from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import { tablePaginationClasses as classes } from "@mui/base/TablePagination";
import LoadingButton from "@mui/lab/LoadingButton";

// import { purple } from "@mui/material/colors";

// export const StyledCircularProgress = styled(CircularProgress)({
//   position: "relative",
//   "&::after": {
//     position: "absolute",
//     top: 0,
//     left: 0,
//     display: "block",
//     width: "100%",
//     height: "100%",
//     backgroundImage: `url(${img.default})`,
//     backgroundRepeat: "no-repeat",
//     backgroundPosition: "center",
//     backgroundSize: "50%",
//     opacity: 0.5, // Adjust the opacity to your liking
//   },
// });

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: "#FFFF00",
    fontFamily: "InterMedium",
    zIndex: 1,
    // overflowX:"hidden",

    // fontSize: 16,
  },
  [`&.${tableCellClasses.body}`]: {
    // fontSize: 14,
    backgroundColor: "#F2F4F5",
    fontFamily: "InterMedium",
  },
}));
export const RiskStyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#F2F4F5",
    color: "#000000",
    fontFamily: "InterMedium",
    border: "1px solid #ACB0B1",
    borderCollapse: "collapse",
    fontWeight: "bold",
    fontSize: "15px",
  },
  [`&.${tableCellClasses.body}`]: {
    // fontSize: 14,
    // backgroundColor: "#FFF",
    fontFamily: "InterMedium",
    borderBottom: "1px solid #dedede",
    borderCollapse: "collapse",
    overflow: "visible",
  },
}));

export const RelevantStyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#F2F4F5",
    color: "#000000",
    fontFamily: "InterMedium",
    border: "1px solid #ACB0B1",
    borderCollapse: "collapse",
    padding: 8,
    // fontSize: 16,
  },
  [`&.${tableCellClasses.body}`]: {
    // fontSize: 14,
    // backgroundColor: "#FFF",
    padding: 8,
    fontFamily: "InterMedium",
    border: "1px solid #ACB0B1",
    borderCollapse: "collapse",
    overflow: "visible",
  },
}));
export const RelevantTitleTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#F8EFAD",
    color: "#000000",
    fontFamily: "InterMedium",
    border: "none",
  },
}));
export const Take5TableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#F8EFAD",
    color: "#000000",
    fontFamily: "InterMedium",
    border: "none",
    fontSize: 16,
  },
}));
export const Take4TableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#ACB0B1",
    color: "#000000",
    fontFamily: "InterMedium",
    border: "none",
    fontSize: 16,
  },
}));

export const RelevantStyledTableCellRow = styled(TableRow)(() => ({
  // hide last border
  boder: 0,
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export const StyledTableRow = styled(TableRow)(() => ({
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
export const CustomTablePagination = styled(TablePagination)(
  () => `
    & .${classes.spacer} {
      display: none; 
      font-family:"InterMedium";
    }
  
    & .${classes.toolbar}  {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;
      background-color:#F2F4F5;
      justify-content: flex-end; 
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;    
    }
  
    & .${classes.selectLabel} {
      margin: 0;
      font-family:'InterMedium';     
    }
  
    & .${classes.select}{
      padding: 2px 6px;
      border: none;
      border-radius: 6px;
      color: #70b619;
      background-color:#e4eede;
      font-family:'InterMedium'; 
    }
  
    & .${classes.displayedRows} {
      margin: 0;
      font-family:'InterMedium'; 
      }
    `
);

export const GreenColorButton = styled(Button)(() => ({
  textTransform: "capitalize",
  color: "#70b619",
  fontFamily: "InterSemiBold",
  backgroundColor: "#e4eede",
  "&:hover": {
    backgroundColor: "#e4eede",
  },
}));
export const YellowColorButton = styled(Button)(() => ({
  textTransform: "capitalize",
  color: "#000000",
  fontFamily: "InterSemiBold",
  fontSize:"16px",
  fontWeight:"bold",
  borderRadius:"8px",
  backgroundColor: "#F4DF3E",
  "&:hover": {
    backgroundColor: "#F4DF3E",
  },
}));
export const YellowLoadColorButton = styled(LoadingButton)(() => ({
  textTransform: "capitalize",
  color: "#000000",
  fontFamily: "InterSemiBold",
  backgroundColor: "#F4DF3E",
  "&:hover": {
    backgroundColor: "#F4DF3E",
  },
}));

export const GreyColorButton = styled(Button)(() => ({
  textTransform: "capitalize",
  color: "#fff",
  backgroundColor: "#ACB0B1",
  fontFamily: "InterSemiBold",
  "&:hover": {
    backgroundColor: "#ACB0B1",
  },
}));
export const UploadButton = styled(Button)(() => ({
  textTransform: "capitalize",
  color: "#ACB0B1",
  backgroundColor: "transparent",
  fontFamily: "InterMedium",
  fontSize: "16px",
  // fontWeight:100,
  width: "98%",
  padding: "1.3%",
  display: "flex",
  paddingLeft: "5%",
  justifyContent: "flex-start",
  border: "1px solid #ACB0B1",
  "&:hover": {
    backgroundColor: "transparent",
  },
}));
export const UploadButtonJob = styled(Button)(() => ({
  textTransform: "capitalize",
  color: "#ACB0B1",
  backgroundColor: "transparent",
  fontFamily: "InterMedium",
  fontSize: "16px",
  // fontWeight:100,
  width: "100%",
  padding: "1.3%",
  display: "flex",
  paddingLeft: "5%",
  justifyContent: "flex-start",
  border: "1px solid #ACB0B1",

  "&:hover": {
    backgroundColor: "transparent",
  },
}));

export const BackButton = styled(Button)(() => ({
  textTransform: "capitalize",
  color: "#000",
  backgroundColor: "transparent",
  border: "1px solid #ACB0B1",
  fontSize:"16px",
  fontWeight:"bold",
  borderRadius:"8px",
  fontFamily: "InterSemiBold",
  "&:hover": {
    backgroundColor: "transparent",
  },
}));

export const VioletColorButton = styled(Button)(() => ({
  textTransform: "capitalize",
  color: "#99658D",
  backgroundColor: "#f0e8ee",
  fontFamily: "InterSemiBold",
  "&:hover": {
    backgroundColor: "#f0e8ee",
  },
}));

export const BlueColorButton = styled(Button)(() => ({
  textTransform: "capitalize",
  color: "#0684fc",
  backgroundColor: "#dbefff",
  fontFamily: "InterSemiBold",
  "&:hover": {
    backgroundColor: "#dbefff",
  },
}));
export const RedColorButton = styled(Button)(() => ({
  textTransform: "capitalize",
  color: "#fff",
  backgroundColor: "#D84B10",
  fontFamily: "InterSemiBold",
  "&:hover": {
    backgroundColor: "#D84B10",
  },
}));

export const CustomStylesReactselect = {
  control: (styles) => ({
    ...styles,
    backgroundColor: "transperent",
    // backgroundColor: "#fff",
    color: "#000",
    border: "1px solid #ACB0B1",
    outline: "0px",
    boxShadow: "0px",
    borderRadius: "8px",
    fontFamily: "InterRegular",
    cursor: "pointer",
    padding: "1.5%",
    "&:hover": {
      border: "1px solid #ACB0B1",
    },
  }),
  menu: (base) => ({
    ...base,
    zIndex: 1,
    fontFamily: "InterRegular",
  }),
  multiValue: (styles) => {
    return {
      ...styles,
      backgroundColor: "#4270EB",
      color: "#fff",
    };
  },
  multiValueLabel: (styles) => ({
    ...styles,
    color: "#fff",
  }),
};

export const CustomStylesReactSelectReport = {
  control: (styles) => ({
    ...styles,
    backgroundColor: "transperent",
    color: "#000",
    border: "1px solid #F5F4F5",
    outline: "0px",
    boxShadow: "0px",
    borderRadius: "8px",
    fontFamily: "InterRegular",
    cursor: "pointer",
    padding: "2%",
    "&:hover": {
      border: "1px solid #F5F4F5",
    },
  }),
  menu: (base) => ({
    ...base,
    zIndex: 10,
    fontFamily: "InterRegular",
  }),
  multiValue: (styles) => {
    return {
      ...styles,
      backgroundColor: "#4270EB",
      color: "#fff",
    };
  },
  multiValueLabel: (styles) => ({
    ...styles,
    color: "#fff",
  }),
};
