import { Grid, Paper } from "@mui/material";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate,useLocation } from "react-router-dom";
import { AiFillLock } from "react-icons/ai";
import { VisibilityOff, Visibility } from "@mui/icons-material";

import CustomInput from "../../Components/Input/CustomInput";
import Main_Logo from "../../Assets/images/OHS-Buddy-Logo.svg";
import AuthBtn from "../../Components/Button/AuthBtn";
import ConfirmationModal from "../../Components/Modal/Modal";
import { postMethod } from "../../Components/Helper/api";

const ResetPassword = () => {
  const route = useLocation();
  const navigate = useNavigate();
  const [NewPassword, setNewPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
  const [PasswordConfirm, setPasswordConfirm] = useState("");
  const [UserEmail,setUserEmail]=useState("")
  const [ShowModal, setShowModal] = useState(false);
  const [Loader, setLoader] = useState(false);
  const HandleClickShowPassword = () => setShowPassword((show) => !show);
  const HandleClickShowPassword_confirm = () => setShowPasswordConfirm((show) => !show);
  const accessToken = route.state.accessToken
  useEffect(()=>{
    if (route.state?.Email) {
      setUserEmail(route.state.Email);
    }else{
      navigate("/")
    }
  },[])
  const validatePassword = (password) => {
    var passwordValidation =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    return passwordValidation.test(password);
  };
  const UpdatePassword = async() => {
    if(!UserEmail){
      toast.error("Please Try again");
      navigate("/")
      return;
    }
    if(!accessToken){
      toast.error("Please Try again");
      navigate("/")
      return;
    }
    if (NewPassword.trim().length == 0) {
      toast.warn("Please enter a valid password");
      return;
    }
    if (NewPassword.trim().length < 8) {
      toast.warn("Your password must be at least 8 characters");
      return;
    }
    if (!validatePassword(NewPassword)) {
      toast.warn("Please enter valid password");
      return;
    }
    if (PasswordConfirm.trim().length === 0) {
      toast.warn("Please enter your Confirm New Password");
      return;
    }
    if (NewPassword !== PasswordConfirm) {
      toast.error("Your New Password and confirm Password do not match");
      return;
    }

    try{
      setLoader(true)
      let url = "superadmin/UpdateForgotPassword";
      let method = "POST"
      let params = {
        Email:UserEmail.toLocaleLowerCase(),
        "Password":NewPassword
      };
      let authtoken = accessToken;
      let response = await postMethod({ url, params, authtoken, method });
      console.log("response",response)
      if(response.status){
        setLoader(false)
        toast.success("Password Update Success");
        navigate("/");
      }else{
        if(response.message){
          toast.error(response.message)
        }else{
          toast.error("Something Went Wrong ")
        }
        setLoader(false)
      }
    }catch(Error){
      setLoader(false)
      toast.error("Something Went Wrong ")
      console.log("UpdatePassword Error",Error )
    }
   
  };
  return (
    <Grid
      container
      lg={12}
      display={"flex"}
      flexDirection={"row"}
      justifyContent={"flex-start"}
      alignItems={"stretch"}
      className="AuthHeight"
    >
      <Grid
        container
        lg={12}
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"flex-start"}
        className=" background_image"
      >
        <Grid
          item
          xl={6}
          lg={6}
          md={6}
          sm={6}
          xs={12}
          className="p-5"
          display={"flex"}
          flexDirection={"column"}
          alignItems={"flex-start"}
          justifyContent={"flex-start"}
        >
          <img src={Main_Logo} alt="main_logo" />
          <div className="w-100 ">
            <h3
              className="text-light mt-5  w-100"
              style={{ textAlign: "left" }}
            >
              Cloud App to create
            </h3>
            <h3 className="d-flex" style={{ color: "var(--Yellow_color)", textAlign: "left" }}>
              JSA, SWMS
              <h3 className="text-light mx-2">
                and 
              </h3>
              Take 5
            </h3>{" "}
          </div>
        </Grid>
        <Grid
          conatiner
          xl={6}
          lg={6}
          md={6}
          sm={6}
          xs={10}
          display={"flex"}
          flexDirection="flex"
          className="p-5"
          justifyContent={"center"}
          alignItems={"center"}
        >
          {" "}
          <Grid
            container
            xxl={6}
            xl={9}
            lg={11}
            display={"flex"}
            flexDierection="column"
          >
            <Paper
              style={{ backgroundColor: "#000" ,opacity: 0.8}}
              className="p-5 rounded w-100"
            >
              <Grid
                container
                lg={12}
                display={"flex"}
                flexDirection="column"
                alignItems={"flex-start"}
                justifyContent={"flex-start"}
              >
                <h4 className="text-light">Update Password</h4>
                <p className="text-light mt-4">New Password</p>
                <div className="p-1" />
                <CustomInput
                  className={" border border-white border-rounded text-light"}
                  placeholder={" New Password"}
                  Value={NewPassword}
                  maxLength={50}
                  placeHolder={"#fff"}
                  TextColor={"#ffff"}
                  BgColor={"#000"}
                  StartIcon={<AiFillLock size={25} />}
                  inputType={showPassword ? "text" : "password"}
                  icon={showPassword ? <VisibilityOff /> : <Visibility />}
                  iconClick={() => HandleClickShowPassword()}
                  iconColor={"#ffff"}
                  isBorder={true}
                  handleInput={(e) => {
                    setNewPassword(e);
                  }}
                />
                <p
                  className="mt-2 text-left m-0"
                  style={{ fontSize: "13px", color: "var(--red_color)" }}
                >
                  Your new password must be different from previously used
                  password
                </p>
                <p className="text-light mt-3" htmlFor="Password">
                  Confirm New Password
                </p>
                <div className="p-1" />
                <CustomInput
                  className={" border border-white border-rounded text-light"}
                  placeholder={"Confirm New Password"}
                  Value={PasswordConfirm}
                  maxLength={50}
                  placeHolder={"#fff"}
                  TextColor={"#ffff"}
                  BgColor={"#000"}
                  StartIcon={<AiFillLock size={25} />}
                  isBorder={true}
                  inputType={showPasswordConfirm ? "text" : "password"}
                  icon={
                    showPasswordConfirm ? <VisibilityOff /> : <Visibility />
                  }
                  iconClick={() => HandleClickShowPassword_confirm()}
                  iconColor={"#ffff"}
                  handleInput={(e) => {
                    setPasswordConfirm(e);
                  }}
                />
                <p
                  className="mt-2 text-left m-0"
                  style={{ fontSize: "13px", color: "var(--red_color)" }}
                >
                  Password Shall Be Minimum 8 Characters With One Uppercase, One
                  Number And One Special Character
                </p>
                <Grid
                  container
                  lg={12}
                  className=" w-100  mt-4 "
                  display={"flex"}
                  flexDirection={"row"}
                  justifyContent={"center"}
                >
                  <AuthBtn
                    text={"Cancel"}
                    btnColor={"#fff"}
                    btnTextColor="#000"
                    onClick={() => setShowModal(true)}
                  />
                  <div className="p-2" />
                  <AuthBtn
                    text={"Update Password"}
                    btnColor={"#FFF241"}
                    btnTextColor="#000"
                    disabled={Loader}
                    onClick={() => UpdatePassword()}
                  />
                </Grid>
              </Grid>
              <div className="p-3" />
            </Paper>
          </Grid>
        </Grid>
      </Grid>
      <ConfirmationModal
        open={ShowModal}
        setOpen={setShowModal}
        width={"35%"}
        modalTitle={
          <h5 className="font-weight-bold">Cancel Update Password</h5>
        }
        modalContent={
          <Grid container lg={12} display={"flex"} flexDierection={"column"}>
            <Grid>
              <p>Are you sure want to Cancel this Update Password Process !</p>
            </Grid>
            <Grid
              container
              diplay={"flex"}
              flexDierection={"row"}
              justifyContent={"flex-end"}
              alignItems={"flex-end"}
              className="mt-3"
            >
              <AuthBtn
                text={"Cancel"}
                btnColor={"var(--red_color)"}
                btnTextColor="#fff"
                onClick={() => setShowModal(false)}
              />
              <div className="p-2" />
              <AuthBtn
                text={"Yes"}
                btnColor={"var(--Yellow_color)"}
                btnTextColor="#000"
                onClick={() => navigate("/")}
              />
            </Grid>
          </Grid>
        }
      />
    </Grid>
  );
};

export default ResetPassword;
