import React from "react";
import Select from "react-select";

const CustomDropDown = ({
  data,
  isSearchable,
  placeholder,
  height,
  selected,
  handleSelected,
  isDisabled,
  isMulti
}) => {
  const selectStyles = {
    control: (provided) => ({
      ...provided,
      height: height ? height : "35px",
    }),
    placeholder: (provided) => ({
      ...provided,
      textAlign: "left",
      fontSize: "15px",
      color: "#A5ABAE",
      fontFamily: "InterMedium",
      fontWeight: 500,
    }),
    option: (provided) => ({
      ...provided,
      textAlign: "left",
      fontSize: "15px",
      fontFamily: "InterMedium",
      fontWeight: 500,
    }),
    singleValue: (provided) => ({
      ...provided,
      textAlign: "left",
      fontSize: "15px",
      fontFamily: "InterMedium",
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999, // Increase the z-index to a high value
    }),
  };

  return (
    <Select
      value={selected}
      isSearchable={isSearchable ? true : false}
      options={data}
      classNamePrefix="my-select"
      styles={selectStyles}
      onChange={(option) => handleSelected(option)}
      placeholder={placeholder || "Select"}
      className="w-100"
      isDisabled={isDisabled ? isDisabled : false}
      isMulti={isMulti}       
    />
  );
};
export default CustomDropDown;
