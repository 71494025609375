import React from "react";
import Grid from "@mui/system/Unstable_Grid/Grid";
export const FormatDate = (newDate) => {
  var a = new Date(parseInt(newDate));
  var months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  var year = a.getFullYear().toString().substr(-2);
  var month = months[a.getMonth()];
  let date = a.getDate();
  var time = a.toLocaleTimeString("en-US", { hour: "2-digit", minute: "2-digit" });
  // var hour = a.getHours();
  // var min = a.getMinutes();
  // var AmOrPm = hour >= 12 ? "PM" : "AM";
  // hour = hour % 12 || 12;
  var dateFormat = date + " " + month + " " + year;
  // var timeFormat = hour + ":" + (min ? "0" + min : min) + " " + AmOrPm;

  return dateFormat + " " + time;
};


export const FormatTimeOnly = (FormateDate) => {
  var a = new Date(parseInt(FormateDate));
  var time = a.toLocaleTimeString("en-US", { hour: "2-digit", minute: "2-digit" });
  // var hour = a.getHours();
  // var min = a.getMinutes();
  // var AmOrPm = hour >= 12 ? "PM" : "AM";
  // hour = hour % 12 || 12;
  // var timeFormat = hour + ":" + (min ? "0" + min : min) + " " + AmOrPm;
  return  time;
};


export const FormatDateOnly = (FormateDate) => {
  var a = new Date(parseInt(FormateDate));
  var monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  var yearName = a.getFullYear().toString().substr(-2);
  var monthName = monthNames[a.getMonth()]; // Subtract 1 since month numbers are zero-based
  let date = a.getDate();
  var formattedDate = date > 9 ? date+ " " + monthName + " " + yearName : "0"+date + " " + monthName + " " + yearName;
  return formattedDate;
};

export const DateAndTime = (newDate , font,colors) => {
  var a = new Date(parseInt(newDate));
  var months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  var year = a.getFullYear().toString().substr(-2);
  var month = months[a.getMonth()];
  let date = a.getDate();
  // var hour = a.getHours();
  // var min = a.getMinutes();
  var time = a.toLocaleTimeString("en-US", { hour: "2-digit", minute: "2-digit" });
  // var AmOrPm = hour >= 12 ? "PM" : "AM";
  // hour = hour % 12 || 12;
  var dateFormat = date + " " + month + " " + year + " " + time;
  // var timeFormat = hour + ":" + (min ? "0" + min : min) + " " + AmOrPm;
  return (
    <Grid container  flexDirection={"row"} >
      <p style={{fontSize:font? "14px":"",color: colors?colors:"#3F606F"}}>{dateFormat}</p>
      {/* <p style={{fontSize:font? "14px":""}}>{timeFormat}</p> */}
    </Grid>
  )
};

export const formatedDateAndTime = (newDate) => {
  var a = new Date(parseInt(newDate));
  var months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  var year = a.getFullYear().toString().substr(-2);
  var month = months[a.getMonth()];
  let date = a.getDate();
  // var hour = a.getHours();
  // var min = a.getMinutes();
  var time = a.toLocaleTimeString("en-US", { hour: "2-digit", minute: "2-digit" });
  // var AmOrPm = hour >= 12 ? "PM" : "AM";
  // hour = hour % 12 || 12;
  var dateFormat = date + " " + month + " " + year + " " + time;
  // var timeFormat = hour + ":" + (min ? "0" + min : min) + " " + AmOrPm;
  return dateFormat
};


export const FormatDateToDate = (data) => {
  var months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  var ISTTime = new Date(data);
  var year = ISTTime.getFullYear().toString().substr(-2);
  var month = months[ISTTime.getMonth()];
  var date = String(ISTTime.getDate() + 0).padStart(2, "0")
  var fullTimes = date + " " + month + " " + year //+ " " + time; //+ " " + year + " " + hour + ":" + min + ":" + sec;  
  return fullTimes;
}
export const FormatDateToDatewithtime = (data) => {
  var months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  var ISTTime = new Date(data);
  var year = ISTTime.getFullYear().toString().substr(-2);
  var month = months[ISTTime.getMonth()];
  var date = String(ISTTime.getDate() + 0).padStart(2, "0")
  var time = ISTTime.toLocaleTimeString("en-US", { hour: "2-digit", minute: "2-digit" });
  var fullTimes = date + " " + month + " " + year + " " + time; //+ " " + year + " " + hour + ":" + min + ":" + sec;  
  return fullTimes;
}

export const RemainingDays = (data) => {
  const targetDate = new Date(data);
  const today = new Date();
  const timeDiff = targetDate.getTime() - today.getTime();
  const daysRemaining = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
  return daysRemaining
}
