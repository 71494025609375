// import "./styles.css";
import React from "react";
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend
} from "recharts";


export default function Barchart({ ServiceCost }) {

    const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
    })

    const CustomTooltip = ({ active, payload }) => {
        if (active && payload && payload.length) {
            return (
                <div className="custom-tooltip" style={{ backgroundColor: "#fff", padding: 15, borderRadius: "10px" }} >
                    {
                        payload.map((data) => (
                            <div style={{flexDirection:"row" , display:"flex" ,justifyContent:"space-between"}} >
                                <p>{`${data.name} :`}</p>
                                <p>{`${formatter.format(data.value)}`}</p>
                            </div>
                        ))
                    }

                </div>
            );
        }

        return null;
    };


    return (
        <BarChart
            width={1000}
            height={400}
            data={ServiceCost}
            margin={{
                top: 20,
                right: 30,
                left: 20,
                bottom: 5
            }}

        >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip content={<CustomTooltip />} cursor={{ fill: 'transparent' }} />
            {/* <Tooltip itemStyle={{alignSelf:"flex-start",alignContent:"flex-start", alignItems:"flex-start"}} labelStyle={{alignSelf:"flex-start",alignContent:"flex-start", alignItems:"flex-start"}} /> */}
            <Legend />
            <Bar dataKey="EC2_Instances" stackId="a" fill="#688ae8" />
            <Bar dataKey="Registrar" stackId="a" fill="#c33d69" />
            <Bar dataKey="Tax" stackId="a" fill="#2ea597" />
            <Bar dataKey="EC2_Other" stackId="a" fill="#8456ce" />
            <Bar dataKey="Rourer53" stackId="a" fill="#e07941" />
            <Bar dataKey="Key_Management_service" stackId="a" fill="#0166ab" />
            <Bar dataKey="s3" stackId="a" fill="#962249" />
            <Bar dataKey="Amplify" stackId="a" fill="#096f64" />
            <Bar dataKey="SNS" stackId="a" fill="#8456CE" />
        </BarChart>
    );
}
